// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDG7YmtGHRBcaDXZ1oQ59IQ0UL9253G6U8",
  authDomain: "chemistry-c3a1e.firebaseapp.com",
  projectId: "chemistry-c3a1e",
  storageBucket: "chemistry-c3a1e.appspot.com",
  messagingSenderId: "1057635051445",
  appId: "1:1057635051445:web:84ee401b77ce42c60c9bfc",
  measurementId: "G-CS3DXTWZEW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getFirestore(app);
export const storage = getStorage(app);