import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserAuth } from './context/AuthContext'
import lightModePng from './lightMode.png'
import darkModePng from './darkMode.png'

const NavBar = () => {
    const {user, logOut} = UserAuth();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleSignOut = async () => {
        try {
            await logOut()
        } catch(error) {
            console.log(error)
        }
    };

    const [theme, setTheme] = useState("light");

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    const handleThemeSwitch = () => {
        setTheme(theme === "dark" ? "light" : "dark");
    };

    let exitSVG = <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6.5C2 4.01472 4.01472 2 6.5 2H12C14.2091 2 16 3.79086 16 6V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V6C14 4.89543 13.1046 4 12 4H6.5C5.11929 4 4 5.11929 4 6.5V17.5C4 18.8807 5.11929 20 6.5 20H12C13.1046 20 14 19.1046 14 18V17C14 16.4477 14.4477 16 15 16C15.5523 16 16 16.4477 16 17V18C16 20.2091 14.2091 22 12 22H6.5C4.01472 22 2 19.9853 2 17.5V6.5ZM18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071C17.9024 15.3166 17.9024 14.6834 18.2929 14.2929L19.5858 13L11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L19.5858 11L18.2929 9.70711C17.9024 9.31658 17.9024 8.68342 18.2929 8.29289Z" fill="currentColor"/>
    </svg>

    return (
        // <div className='flex justify-between bg-blue-100 dark:bg-slate-700 w-full p-4'>
        //     <h1 className='text-center text-xl sm:text-2xl md:text-2xl lg:text-2xl font-bold'>
        //         <div className='flex flex-row items-center space-x-1'>
        //             <img src='logo200.jpg' className='h-12 w-12 rounded-3xl' />
        //             <Link to='/' className='text-black dark:text-white text-sm sm:text-2xl'>Professor Alexey</Link>
        //         </div>
        //     </h1>
        //     {user?.uid ? <div className='flex flex-row items-center'><Link className='hover:shadow-lg px-3 py-2 mt-0 text-sm xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-blue-500 dark:bg-indigo-600 text-white rounded-full' to='/loading'>Личный кабинет</Link> <button onClick={handleSignOut} className='hover:shadow-lg px-3 py-2 mt-0 text-sm xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-white dark:bg-black text-gray-800 dark:text-white rounded-full'>Выйти</button><button onClick={handleThemeSwitch} className="w-10 h-10 rounded-full p-1"><img className='w-6 h-6' src={theme === "light" ? darkModePng : lightModePng} /></button></div> : <div className='flex flex-row items-center space-x-1'><Link className='hover:shadow-lg px-3 py-3 mt-0 xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-blue-500 dark:bg-indigo-600 text-white rounded-full' to='/signin'>Войти / Зарегистрироваться</Link><button onClick={handleThemeSwitch} className="w-10 h-10 rounded-full"><img className='w-6 h-6' src={theme === "light" ? darkModePng : lightModePng} /></button></div>}
            
        // </div>
        <div>
            <nav className='flex flex-col sm:flex-row align-start sm:justify-between bg-blue-100 dark:bg-slate-700 w-full pb-0'>
                <div className='flex flex-row items-center space-x-1'>
                    <img src='logo200.jpg' className='h-12 w-12 rounded-3xl' />
                    <Link to='/' className='text-black dark:text-white text-sm sm:text-2xl'>Professor Alexey</Link>
                </div>
                <div onClick={() => {setMenuOpen(!menuOpen)}} className='cursor-pointer flex sm:hidden absolute top-3 right-2 flex-col justify-between w-9 h-8'>
                    <span className='h-1.5 w-full bg-blue-500 dark:bg-white rounded'></span>
                    <span className='h-1.5 w-full bg-blue-500 dark:bg-white rounded'></span>
                    <span className='h-1.5 w-full bg-blue-500 dark:bg-white rounded'></span>
                </div>
                <ul className={menuOpen ? 'flex-col w-full mb-1 sm:mb-0 sm:w-fit sm:flex sm:flex-row items-center' : 'hidden flex-col w-full mb-1 sm:mb-0 sm:w-fit sm:flex sm:flex-row items-center'}>
                    <li className='px-3 hover:underline hover:underline-offset-8 sm:w-fit w-full text-center text-lg sm:text-lg text-black dark:text-white'>
                        <Link to='/about'>Обо мне</Link>
                    </li>
                    {/* <li className='px-3 hover:underline hover:underline-offset-8 sm:w-fit w-full text-center text-lg sm:text-lg text-black dark:text-white'>
                        <Link>Contact</Link>
                    </li> */}
                    <li className={menuOpen ? 'pt-3 sm:w-fit w-full text-center text-lg sm:text-lg text-black dark:text-white' : 'hidden'}>
                        <div className={menuOpen ? 'w-full pb-2 shadow-sm' : ''}>{user?.uid ? <div className='flex flex-row items-center justify-evenly'><Link className='hover:shadow-lg px-3 py-2 mt-0 text-sm xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-blue-500 dark:bg-indigo-600 text-white rounded-md' to='/loading'>Личный кабинет</Link> <button onClick={handleSignOut} className='hover:shadow-lg px-3 py-2 mt-0 text-sm xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-3.5 sm:py-3.5 sm:mt-2 sm:text-md md:px-3.5 md:py-3.5 md:mt-2 md:text-md lg:px-2.5 lg:py-2.5 lg:mt-2 lg:text-md text-center bg-white dark:bg-black text-gray-800 dark:text-white rounded-md'>Выйти</button><button onClick={handleThemeSwitch} className="w-10 h-10 rounded-full p-1"><img className='w-6 h-6' src={theme === "light" ? darkModePng : lightModePng} /></button></div> : <div className='flex flex-row items-center space-x-1 justify-evenly'><Link className='hover:shadow-lg px-3 py-3 mt-0 xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-blue-500 dark:bg-indigo-600 text-white rounded-md' to='/signin'>Войти / Зарегистрироваться</Link><button onClick={handleThemeSwitch} className="w-10 h-10 rounded-full"><img className='w-6 h-6' src={theme === "light" ? darkModePng : lightModePng} /></button></div>}</div>
                    </li>
                </ul>
                <div className={menuOpen ? 'hidden' : 'pb-2'}>{user?.uid ? <div className='hidden sm:flex sm:flex-row sm:items-center space-x-1'><Link className='hover:shadow-lg px-3 py-2 mt-0 text-sm xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-blue-500 dark:bg-indigo-600 text-white rounded-full' to='/loading'>Личный кабинет</Link> <button onClick={handleSignOut} className='hover:shadow-lg px-3 py-2 mt-0 text-sm xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-3.5 sm:py-3.5 sm:mt-2 sm:text-md md:px-2.5 md:py-2.5 md:mt-2 md:text-md lg:px-2.5 lg:py-2.5 lg:mt-2 lg:text-md text-center bg-white dark:bg-black text-gray-800 dark:text-white rounded-full'>{exitSVG}</button><button onClick={handleThemeSwitch} className="w-10 h-10 rounded-full p-1"><img className='w-6 h-6' src={theme === "light" ? darkModePng : lightModePng} /></button></div> : <div className='hidden sm:flex sm:flex-row sm:items-center space-x-1'><Link className='hover:shadow-lg px-3 py-3 mt-0 xs:px-6 xs:py-5 xs:mt-2 xs:text-md sm:px-6 sm:py-3.5 sm:mt-2 sm:text-md md:px-6 md:py-3.5 md:mt-2 md:text-md lg:px-6 lg:py-3.5 lg:mt-2 lg:text-md text-center bg-blue-500 dark:bg-indigo-600 text-white rounded-full' to='/signin'>Войти / Зарегистрироваться</Link><button onClick={handleThemeSwitch} className="w-10 h-10 rounded-full"><img className='w-6 h-6' src={theme === "light" ? darkModePng : lightModePng} /></button></div>}</div>
            </nav>
        </div>
    )
}

export default NavBar