import React, { useEffect, useState } from "react";
import { GoogleButton } from 'react-google-button';
import { UserAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";

const Signin = () => {
    const { googleSignIn, user, createUserWithEmail, signInWithEmail } = UserAuth();
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch(error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if(user != null) {
            navigate('/loading')
        }
    }, [user])

    const [checked, setChecked] = useState(false)
    const handleClick = () => setChecked(!checked)

    // signUp and signIn with Email
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmitSignUpWithEmail = async (e) => {
        e.preventDefault()
        setError('')
        try{
            await createUserWithEmail(email, password)
        } catch (e) {
            setError(e.message)
            console.log(e.message)
        }
    }

    const handleSubmitSignInWithEmail = async (e) => {
        e.preventDefault()
        setError('')
        try{
            await signInWithEmail(email, password)
        } catch (e) {
            setError(e.message)
            console.log(e.message)
        }
    }

    return (
        <div className="flex flex-col">
            <h1 className="text-center text-3xl font-bold pt-8">Вход</h1>
            <h3 className="text-center text-sm font-small">Необходимо согласиться на обработку персональных данных под формами</h3>
            <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-center items-center">
                <form onSubmit={handleSubmitSignUpWithEmail} className="flex flex-col justify-center items-center border border-grey-500 rounded-lg p-3">
                <h3 className="text-gray-500">Я новый пользователь</h3>
                    <div className="flex flex-col py-2 px-4 max-w-[700px]">
                        <label className="py-0 font-small text-right text-sm">Email</label>
                        <input onChange={(e)=> setEmail(e.target.value)} className="border p-3 rounded-lg" type="email" />
                    </div>
                    <div className="flex flex-col px-4 max-w-[700px]">
                        <label className="py-0 font-small text-right text-sm">Пароль</label>
                        <input onChange={(e)=> setPassword(e.target.value)} className="border p-3 rounded-lg" type="password" />
                    </div>
                    <button className={!checked ? "border border-gray-300 bg-gray-400 max-w-[700px] p-3 my-2 text-white rounded-lg" : "border border-blue-400 bg-blue-500 hover:bg-blue-400 max-w-[700px] p-3 my-2 text-white rounded-lg"} disabled={!checked}>Зарегистрироваться</button>
                </form>
                <h1 className="font-black px-5">ИЛИ</h1>
                <form onSubmit={handleSubmitSignInWithEmail} className="flex flex-col justify-center items-center border border-grey-500 rounded-lg p-3">
                <h3 className="text-gray-500">Я уже зарегистрирован(а)</h3>
                    <div className="flex flex-col py-2 px-4 max-w-[700px]">
                        <label className="py-0 font-small text-right text-sm">Email</label>
                        <input onChange={(e)=> setEmail(e.target.value)} className="border p-3 rounded-lg" type="email" />
                    </div>
                    <div className="flex flex-col px-4 max-w-[700px]">
                        <label className="py-0 font-small text-right text-sm">Пароль</label>
                        <input onChange={(e)=> setPassword(e.target.value)} className="border p-3 rounded-lg" type="password" />
                    </div>
                    <button className={!checked ? "border border-gray-300 bg-gray-400 max-w-[700px] p-3 my-2 text-white rounded-lg" : "border border-blue-400 bg-blue-500 hover:bg-blue-400 max-w-[700px] p-3 my-2 text-white rounded-lg"} disabled={!checked}>Войти</button>
                </form>
            </div>
            <div className="max-w-[240px] m-auto py-4">
                <GoogleButton onClick={handleGoogleSignIn} disabled={!checked} />
            </div>
            <div className="flex justify-center w-full px-2">
                <input type="checkbox" onChange={handleClick} checked={checked} className="w-4 h-4 mr-2" name="checkbox" />
                <p>Я соглашаюсь на обработку моих персональных данных в соответствии с&nbsp;<button onClick={(e) => document.getElementById("personalPolicy").classList.remove("hidden")} className="underline text-sky-600">Условиями</button></p>
            </div>
            <div className="px-6 mt-6 mb-6 hidden" id="personalPolicy">
                <p>ИНН 503013304848</p>
                <p>Самозанятый гражданин Румянцев Алексей Александрович</p>
                <p>1 января 2021 года</p>
                <p className="flex justify-center"><b>Политика обработки персональных данных</b></p>
                <p>1. ВВЕДЕНИЕ</p>
                <p>1.1. Политика обработки персональных данных (далее — «Политика») издана и применяется самозанятым гражданином Румянцевым Алексеем Александровичем (далее — «Оператор») в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных». Настоящая Политика определяет порядок и условия Оператора в отношении обработки персональных данных, устанавливает положения, направленные на соблюдение законодательства Российской Федерации, касающееся обработки персональных данных. Все вопросы, связанные с обработкой персональных данных, не урегулированные настоящей Политикой, разрешаются в соответствии с действующим законодательством Российской Федерации в области персональных данных.</p>
                <p>2. ОСНОВНЫЕ ПОЛОЖЕНИЯ</p>
                <p>2.1. Целью обработки персональных данных является: предоставление услуг Оператором по поручению субъекта персональных данных.</p>
                <p>2.2. Обработка организована Оператором на принципах:
                    <ul>
                        <li>
                            законности целей и способов обработки персональных данных, добросовестности и справедливости в деятельности Оператора;
                        </li>
                        <li>
                            достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;
                        </li>
                        <li>
                            обработки только тех персональных данных, которые отвечают целям их обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;
                        </li>
                        <li>
                            соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки.
                        </li>
                        <li>
                            недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, не совместимых между собой;
                        </li>
                        <li>
                            обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;
                        </li>
                        <li>
                            хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных;
                        </li>
                        <li>
                            обеспечения записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения персональных данных граждан РФ с использованием баз данных, находящихся на территории РФ.
                        </li>
                    </ul>
                </p>
                <p>2.3. Оператор обрабатывает следующие персональные данные:
                    <ul>
                        <li>
                            имя, фамилию субъекта персональных данных;
                        </li>
                        <li>
                            номер телефона субъекта персональных данных;
                        </li>
                        <li>
                            электронную почту субъекта персональных данных.
                        </li>
                    </ul>
                </p>
                <p>2.4. Персональные данные, указанные выше обрабатываются с использованием средств автоматизации и без использования средств автоматизации. При обработке персональных данных без использования средств автоматизации Оператор руководствуется Положением об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации, утвержденной Постановлением Правительства Российской Федерации от 15 сентября 2008 г. N 687.</p>
                <p>2.5. При обработке персональных данных Оператор применяет правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных». Оператор предоставляет услуги посредством размещения своего интернет-сайта на сайте https://him100test.ru, представляющей собой программное обеспечение, предназначенное для организации процесса обучения в сети Интернет. На сайте him100test.ru используется расширение HTTPS к протоколу HTTP в целях повышения безопасности и защиты информации.</p>
                <p>2.6. Оператор не раскрывает третьим лицам и не распространяет персональные данные без согласия субъекта персональных данных, кроме случаев, предусмотренных действующим законодательством РФ и настоящей Политикой.</p>
                <p>2.7. Оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Оператором требований Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», определяется в соответствии со ст. ст. 15, 151, 152, 1101 Гражданского кодекса Российской Федерации.</p>
                <p>2.7.1. Соотношение указанного вреда и принимаемых Оператором мер, направленных на предупреждение, недопущение и/или устранение его последствий, определяется в соответствии с ФЗ РФ от 27.07.2006 N 152-ФЗ «О персональных данных».</p>
                <p>2.8. Условия обработки персональных данных Оператором:
                    <ol>
                        <li>
                            персональные данные обрабатываются Оператором после акцепта субъектом персональных данных оферты на оказание услуг.
                        </li>
                        <li>
                            согласно п. 5 статьи 6 Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных» субъект персональных данных акцептует оферту по собственной инициативе и является стороной и выгодоприобретателем по заключенному им договору. Отдельное согласие на обработку персональных данных субъекта в таком случае не требуется.
                        </li>
                        <li>
                            условия настоящей Политики доступны пользователям в открытом доступе, до момента акцепта оферты на оказание услуг.
                        </li>
                    </ol>
                </p>
                <p>2.9. Хранение персональных данных пользователей осуществляется в форме, позволяющей определить субъекта персональных данных.</p>
                <p>2.10. Персональные данные подлежат уничтожению по достижении целей обработки. Удаление персональных данных осуществляется посредством удаления учетной записи, после чего такая запись не подлежит восстановлению.</p>
                <p>2.11. Взаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных субъектов, персональные данные которых обрабатываются Оператором, осуществляется в рамках законодательства Российской Федерации.</p>
                <p>3. ТРЕТЬИ ЛИЦА, УЧАСТВУЮЩИЕ В ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                <p>3.1. Услуги хостинга и услуги предоставления выделенного сервера предоставляются ООО "Авгуро Технолоджис" https://jino.ru/about/financial-details/</p>
                <p>4. ОТВЕТСТВЕННЫЙ ЗА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                <p>4.1. Ответственным за обработку персональных данных является Оператор или его законный представитель.</p>
                <p>4.2. Ответственный за обработку персональных данных:
                    <ul>
                        <li>
                            осуществляет внутренний контроль за соблюдением законодательства Российской Федерации о персональных данных, в том числе требований к защите персональных данных;
                        </li>
                        <li>
                            контролирует прием и обработку обращений и запросов субъектов персональных данных или их представителей;
                        </li>
                        <li>
                            принимает меры для обнаружения фактов несанкционированного доступа к персональным данным;
                        </li>
                        <li>
                            производит постоянный контроль за обеспечением уровня защищенности персональных данных;
                        </li>
                        <li>
                            осуществляет внутренний контроль и (или) аудит соответствия обработки персональных данных Федеральному закону от 27.07.2006 N 152-ФЗ «О персональных данных» и принятым в соответствии с ним нормативными правовыми актами.
                        </li>
                    </ul>
                </p>
                <p>5. ПОРЯДОК ОБЕСПЕЧЕНИЯ ОПЕРАТОРОМ ПРАВ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                <p>5.1. Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных».</p>
                <p>5.2. Оператор обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».</p>
                <p>5.3. Полномочия представителя на представление интересов каждого субъекта персональных данных подтверждаются соответствующей доверенностью.</p>
                <p>5.4. Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», предоставляются субъекту персональных данных в доступной форме без персональных данных, относящихся к другим субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных, в электронном виде. По требованию субъекта персональных данных они могут быть продублированы на бумаге.</p>
                <p>5.5. Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», предоставляются субъекту персональных данных или его представителю при личном обращении либо при получении запроса субъекта персональных данных или его представителя. Запрос должен содержать номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись субъекта персональных данных или его представителя. При наличии технической возможности запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.</p>
                <p>5.6. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.</p>
                <p>5.7. Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных, по месту своего расположения в рабочее время.</p>
            </div>
        </div>
    )
}

export default Signin;