import React from "react";
import chemicalFlask from "./chemical_flask.png";
import molecule1 from "./molecule1.png";
import molecule2 from "./molecule2.png";
import laptop from "./laptop.png";
import profe from "./profe.jpg";
import ege from "./ege.jpeg";
import organica from "./organica.jpeg";
import oge from "./oge.jpeg";

function MainPage() {

    return (
        <div className="dark:bg-slate-900 pb-6">
            <div className="relative flex flex-col justify-center sm:flex-row sm:justify-around h-96 items-center bg-blue-100 dark:bg-slate-700">
                <div className="flex flex-col justify-center items-left pl-6">
                    <h1 className="mb-0 text-3xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-4xl sm:text-3xl xs:text-xl mt-4"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Лаборатория тестов</span></h1>
                    <h1 className="mb-0 text-3xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-4xl sm:text-3xl xs:text-xl">Алексея Румянцева</h1>
                    <h1 className="mb-4 text-xl font-extrabold text-gray-900 dark:text-white md:text-2xl lg:text-2xl sm:text-xl xs:text-md">для подготовки к ЕГЭ</h1>
                </div>
                <img src={chemicalFlask} className="lg:w-80 lg:h-auto md:w-72 lg:h-auto sm:w-72 lg:h-auto lg:w-72 h-auto w-72" />
                <div className="absolute top-24 left-1/4 sm:top-28 sm:left-2/4 md:top-24 md:left-2/3 lg:left-2/3 lg:top-16 floating-4">
					<img className="h-20 sm:h-24" src={molecule1} />
				</div>
                <div className="absolute top-40 right-1/4 sm:top-40 sm:right-6 md:top-40 md:right-10 lg:left-3/4 lg:top-36 floating">
					<img className="h-20 sm:h-24" src={molecule2} />
				</div>
            </div>
            <div className="text-white dark:text-slate-900 -mt-14 sm:-mt-24 lg:-mt-36 z-40 relative">
                <svg className="xl:h-40 xl:w-full" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" fill="currentColor"></path>
                </svg>
                <div className="bg-white dark:bg-slate-900 w-full h-9 sm:h-9 md:h-6 lg:h-11 -mt-px"></div>
            </div>

            <div className="max-w-3xl mx-auto text-center mt-0 px-2">
                <h1 className="font-bold text-darken my-3 text-3xl dark:text-white">Репетитор по <span className="text-amber-500">ХИМИИ</span></h1>
                <p className="leading-relaxed text-gray-500 text-xl">Подготовка к ЕГЭ, ОГЭ, ДВИ, олимпиадам, поступлению в профильные школы и лицеи, предметная диагностика. Получи доступ к десяткам различных материалов. Общайся напрямую и учись с экспертом ЕГЭ. Автор курсов - Румянцев Алексей Александрович</p>
            </div>

            <div className="md:flex mt-0 md:space-x-10 items-start">
                <div className="w-full md:w-7/12 relative flex justify-center">
                    <div style={{background: "#33D9EF"}} className="w-5 h-5 rounded-full absolute z-0 left-36 sm:left-36 md:left-16 lg:left-36 top-10 animate-ping"></div>
                    <img className="relative z-10 floating h-96 w-96 md:h-80 md:w-80 left-0 lg:left-16" src={laptop} alt="" />
                    <div style={{background: "#F56666"}} className="w-5 h-5 rounded-full absolute z-0 right-24 sm:right-36 md:right-12 lg:right-0 bottom-10 animate-ping"></div>
                </div>
                <div className="md:w-10/12 mt-0 px-2 md:mt-20 text-gray-500">
                    <h1 className="text-2xl font-semibold text-darken lg:pr-40 dark:text-white">Комфортное<span className="text-amber-500"> и грамотное обучение.</span> Лучшие результаты</h1>
                    <div className="flex items-center space-x-5 my-5">
                        <p>Моя повседневная педагогическая практика показывает, что онлайн обучение дает гораздо больше инструментов для достижения наилучших результатов в учебе. Вы учитесь, где вам удобно и когда вам удобно. При этом постоянный контакт с педагогом за счет удаленных технологий становится еще более тесным</p>
                    </div>
                </div>
            </div>

            <div className="max-w-3xl mx-auto text-center mt-0 px-2">
                <h1 className="font-bold text-darken my-3 text-3xl dark:text-white">Основные <span className="text-amber-500">курсы:</span></h1>
            </div>

            <div class="grid lg:grid-cols-3 gap-14 md:gap-5 mt-10 px-5">
                <div className="bg-white dark:bg-slate-700 shadow-xl dark:shadow-amber-900 p-6 text-center rounded-xl">
                    {/* <div className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-6 bg-amber-500">
                        <p className="text-2xl text-white font-black">ЕГЭ</p>
                    </div> */}
                    <div className="flex flex-row justify-center">
                        <img className="h-48 sm:h-64 lg:h-48 2xl:h-64 rounded-xl" src={ege} />
                    </div>
                    <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken dark:text-white">ЕГЭ ПО ХИМИИ НА 90+</h1>
                    <p className="px-4 text-gray-500">ПОЭТАПНАЯ СИСТЕМА, БЛАГОДАРЯ КОТОРОЙ ВЫ ЗА 9 МЕСЯЦЕВ ПОДГОТОВИТЕСЬ И СДАДИТЕ ЕГЭ НА 90+ БАЛЛОВ</p>
                    <p className="px-4 text-gray-500 text-left dark:text-white">Вы сможете:</p>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-amber-400 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Структурировать весь материал профильной школьной программы по химии</p>
				    </div>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-amber-400 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Выучить все нужные термины и правильно их использовать</p>
				    </div>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-amber-400 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Решать задания из всех разделов ЕГЭ, включая вторую часть</p>
				    </div>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-amber-400 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Грамотно распределять время на экзамене</p>
				    </div>
                </div>

                <div className="bg-white dark:bg-slate-700 shadow-xl dark:shadow-blue-900 p-6 text-center rounded-xl">
                    {/* <div className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-6 bg-blue-500">
                        <p className="text-2xl text-white font-black">ОРГ</p>
                    </div> */}
                    <div className="flex flex-row justify-center">
                        <img className="h-48 sm:h-64 lg:h-48 2xl:h-64 rounded-xl" src={organica} />
                    </div>
                    <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken dark:text-white">ОРГАНИКА: просто</h1>
                    <p className="px-4 text-black text-left text-xl dark:text-gray-500">Практический курс по органической химии с нуля.</p>
                    <p className="px-4 text-gray-500 text-left text-lg dark:text-white">Научитесь решать все задания из этого раздела химии. Поможет тем, кто только начал осваивать школьную программу по органике, а также нужен одиннадцатиклассникам для углубленного повторения и подготовки к экзамену.</p>
                </div>

                <div className="bg-white dark:bg-slate-700 shadow-xl dark:shadow-red-900 p-6 text-center rounded-xl">
                    {/* <div className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-6 bg-red-400">
                        <p className="text-2xl text-white font-black">ОГЭ</p>
                    </div> */}
                    <div className="flex flex-row justify-center">
                        <img className="h-48 sm:h-64 lg:h-48 2xl:h-64 rounded-xl" src={oge} />
                    </div>
                    <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken dark:text-white">ОГЭ ПО ХИМИИ НА ОТЛИЧНО</h1>
                    <p className="px-4 text-gray-500">ПОЭТАПНАЯ СИСТЕМА, БЛАГОДАРЯ КОТОРОЙ ВЫ ЗА 9 МЕСЯЦЕВ ПОДГОТОВИТЕСЬ И СДАДИТЕ ОГЭ НА ОТЛИЧНО</p>
                    <p className="px-4 text-gray-500 text-left dark:text-white">Вы сможете:</p>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-red-300 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Структурировать весь материал 8-9 класса школьной программы по химии</p>
				    </div>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-red-300 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Выучить все нужные термины и правильно их использовать</p>
				    </div>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-red-300 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Решать задания из всех разделов ОГЭ, включая вторую часть</p>
				    </div>
                    <div className="flex items-center space-x-5 my-1">
                        <div className="flex-shrink bg-red-300 shadow-lg rounded-lg p-2 flex items-center justify-center"></div>
                        <p className="text-left dark:text-white">Грамотно распределять время на экзамене</p>
				    </div>
                </div>
            </div>

            <div class="sm:flex items-center sm:space-x-8 mt-36 mb-10 px-2">
                <div className="sm:w-1/2 relative lg:ml-20">
                    <div className="bg-amber-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
                    <h1 className="font-semibold text-2xl relative z-50 text-darken lg:pr-10 dark:text-white">Автор онлайн курсов Румянцев Алексей <br></br><span className="text-amber-500">ПОЧЕМУ НУЖНО ЗАНИМАТЬСЯ У МЕНЯ?</span></h1>
                    <p className="py-5 lg:pr-32 dark:text-white">Я изучил лучшие методики обучения химии и преобразовал их под конкретные задачи своих учеников.</p>
                    <div class="flex items-center space-x-5 my-1">
                        <div class="flex-shrink bg-white dark:bg-slate-700 shadow-lg rounded-lg p-2 flex items-center justify-center">
                            <svg class="w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="21.2245" height="21.2245" rx="2" fill="#60a5fa"/>
                            </svg>
                        </div>
                        <p className="dark:text-white">Сертифицированный эксперт ЕГЭ по химии с правом проверки экзамена</p>
				    </div>
                    <div class="flex items-center space-x-5 my-1">
                        <div class="flex-shrink bg-white dark:bg-slate-700 shadow-lg rounded-lg p-2 flex items-center justify-center">
                            <svg class="w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="21.2245" height="21.2245" rx="2" fill="#60a5fa"/>
                            </svg>
                        </div>
                        <p className="dark:text-white">Всегда в курсе всех изменений в экзаменах</p>
				    </div>
                    <div class="flex items-center space-x-5 my-1">
                        <div class="flex-shrink bg-white dark:bg-slate-700 shadow-lg rounded-lg p-2 flex items-center justify-center">
                            <svg class="w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="21.2245" height="21.2245" rx="2" fill="#60a5fa"/>
                            </svg>
                        </div>
                        <p className="dark:text-white">Провожу 1300 уроков ежегодно</p>
				    </div>
                    <div class="flex items-center space-x-5 my-1">
                        <div class="flex-shrink bg-white dark:bg-slate-700 shadow-lg rounded-lg p-2 flex items-center justify-center">
                            <svg class="w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="21.2245" height="21.2245" rx="2" fill="#60a5fa"/>
                            </svg>
                        </div>
                        <p className="dark:text-white">Преподаю в медицинских классах</p>
				    </div>
                    <div class="flex items-center space-x-5 my-1">
                        <div class="flex-shrink bg-white dark:bg-slate-700 shadow-lg rounded-lg p-2 flex items-center justify-center">
                            <svg class="w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="21.2245" height="21.2245" rx="2" fill="#60a5fa"/>
                            </svg>
                        </div>
                        <p className="dark:text-white">Большой опыт. Обучаю ежегодно более 300 учеников</p>
				    </div>
                    <div class="flex items-center space-x-5 my-1">
                        <div class="flex-shrink bg-white dark:bg-slate-700 shadow-lg rounded-lg p-2 flex items-center justify-center">
                            <svg class="w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="21.2245" height="21.2245" rx="2" fill="#60a5fa"/>
                            </svg>
                        </div>
                        <p className="dark:text-white">Мои ученики, обучаясь с нулевого уровня поступают на бюджет</p>
				    </div>
                </div>
                <div className="sm:w-1/2 relative mt-10 sm:mt-0">
                    <div style={{background: "#23BDEE"}} className="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
                    <img className="rounded-xl z-40 relative lg:w-96 lg:h-84" src={profe} alt="" />
                    <div className="bg-amber-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3 lg:left-60"></div>
                </div>
		    </div>
        </div>
    )
}

export default MainPage;