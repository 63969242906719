import React, { useState, useEffect } from "react";
import { database } from "./firebase";
import { addDoc, collection, updateDoc, doc, getDocs, deleteDoc, query, where, setDoc } from "firebase/firestore";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 } from "uuid";
import { Tooltip } from 'react-tooltip'
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from "./PDF";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AccountAdmin = () => {

    const [numberOfEx, setNumberOfEx] = useState([0]);
    const [data, setData] = useState([]);
    const [toggle, setToggle] = useState(false);

    const [content, setContent] = useState("");
    const addFirstExercise = <AddFirstExercise />;
    const addSecondExercise = <AddSecondExercise />;
    const addThirdExercise = <AddThirdExercise />;
    const addFourExercise = <AddFourExercise />;
    const addFifthExercise = <AddFifthExercise />;
    const addSixthExercise = <AddSixthExercise />;
    const addSeventhExercise = <AddSeventhExercise />;
    const addEightExercise = <AddEightExercise />;
    const addNinethExercise = <AddNinethExercise />;
    const addTenthExercise = <AddTenthExercise />;
    const addElevenExercise = <AddElevenExercise />;
    const addTwelveExercise = <AddTwelveExercise />;
    const addThirteenExercise = <AddThirteenExercise />;
    const addFourteenExercise = <AddFourteenExercise />;
    const addFifteenExercise = <AddFifteenExercise />;
    const addSixteenExercise = <AddSixteenExercise />;
    const addSeventeenExercise = <AddSeventeenExercise />;
    const addEighteenExercise = <AddEighteenExercise />;
    const addNineteenExercise = <AddNineteenExercise />;
    const addTwentyExercise = <AddTwentyExercise />;
    const addTwentyOneExercise = <AddTwentyOneExercise />;
    const addTwentyTwoExercise = <AddTwentyTwoExercise />;
    const addTwentyThreeExercise = <AddTwentyThreeExercise />;
    const addTwentyFourExercise = <AddTwentyFourExercise />;
    const addTwentyFiveExercise = <AddTwentyFiveExercise />;
    const addTwentySixExercise = <AddTwentySixExercise />;
    const addTwentySevenExercise = <AddTwentySevenExercise />;
    const addTwentyEightExercise = <AddTwentyEightExercise />;
    const addTwentyNineExercise = <AddTwentyNineExercise />;
    const addThirtyExercise = <AddThirtyExercise />;
    const addThirtyOneExercise = <AddThirtyOneExercise />;
    const addThirtyTwoExercise = <AddThirtyTwoExercise />;
    const addThirtyThreeExercise = <AddThirtyThreeExercise />;
    const addThirtyFourExercise = <AddThirtyFourExercise />;
    const studentResults = <StudentResults />;
    const showAllExercises = <ShowAllExercises data={data} numberOfEx={numberOfEx} />;
    const showImageBase = <ImageBase />;
    const studentSubscription = <StudentSubscription />;

    const setActiveButton = (e) => {
        var buttons = document.querySelectorAll('[data-mainMenuButton]');
        for(let i = 0; i<buttons.length; i++) {
            buttons[i].classList.remove("bg-orange-400");
            buttons[i].classList.add("bg-cyan-500");
        };
        e.target.classList.remove("bg-cyan-500");
        e.target.classList.add("bg-orange-400");
    };

    function toggleReload() {
        if(toggle === false) {
            setToggle(true)
        } else {
            setToggle(false)
        }
    };

    function handleShowStudentSubscriptions(e) {
        setContent(studentSubscription);
        setActiveButton(e);
    };

    function handleShowStudentResults(e) {
        setContent(studentResults);
        setActiveButton(e);
    };

    function handleShowAddFirstExercise(e) {
        setContent(addFirstExercise);
        setActiveButton(e);
    };

    function handleShowAddSecondExercise(e) {
        setContent(addSecondExercise);
        setActiveButton(e);
    };

    function handleShowAddThirdExercise(e) {
        setContent(addThirdExercise);
        setActiveButton(e);
    };

    function handleShowAddFourExercise(e) {
        setContent(addFourExercise);
        setActiveButton(e);
    };

    function handleShowAddFifthExercise(e) {
        setContent(addFifthExercise);
        setActiveButton(e);
    };

    function handleShowAddSixthExercise(e) {
        setContent(addSixthExercise);
        setActiveButton(e);
    };

    function handleShowAddSeventhExercise(e) {
        setContent(addSeventhExercise);
        setActiveButton(e);
    };

    function handleShowAddEightExercise(e) {
        setContent(addEightExercise);
        setActiveButton(e);
    };

    function handleShowAddNinethExercise(e) {
        setContent(addNinethExercise);
        setActiveButton(e);
    };

    function handleShowAddTenthExercise(e) {
        setContent(addTenthExercise);
        setActiveButton(e);
    };

    function handleShowAddElevenExercise(e) {
        setContent(addElevenExercise);
        setActiveButton(e);
    };

    function handleShowAddTwelveExercise(e) {
        setContent(addTwelveExercise);
        setActiveButton(e);
    };

    function handleShowAddThirteenExerciseText(e) {
        setContent(addThirteenExercise);
        setActiveButton(e);
    };

    function handleShowAddFourteenExercise(e) {
        setContent(addFourteenExercise);
        setActiveButton(e);
    };

    function handleShowAddFifteenExercise(e) {
        setContent(addFifteenExercise);
        setActiveButton(e);
    };
    
    function handleShowAddSixteenExerciseText(e) {
        setContent(addSixteenExercise);
        setActiveButton(e);
    };

    function handleShowAddSeventeenExerciseText(e) {
        setContent(addSeventeenExercise);
        setActiveButton(e);
    };

    function handleShowAddEighteenExerciseText(e) {
        setContent(addEighteenExercise);
        setActiveButton(e);
    };

    function handleShowAddNineteenExerciseText(e) {
        setContent(addNineteenExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyExerciseText(e) {
        setContent(addTwentyExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyOneExerciseText(e) {
        setContent(addTwentyOneExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyTwoExerciseText(e) {
        setContent(addTwentyTwoExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyThreeExerciseText(e) {
        setContent(addTwentyThreeExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyFourExerciseText(e) {
        setContent(addTwentyFourExercise);
        setActiveButton(e);
    };
    function handleShowAddTwentyFiveExerciseText(e) {
        setContent(addTwentyFiveExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentySixExerciseText(e) {
        setContent(addTwentySixExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentySevenExerciseText(e) {
        setContent(addTwentySevenExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyEightExerciseText(e) {
        setContent(addTwentyEightExercise);
        setActiveButton(e);
    };

    function handleShowAddTwentyNineExerciseText(e) {
        setContent(addTwentyNineExercise);
        setActiveButton(e);
    };

    function handleShowAddThirtyExerciseText(e) {
        setContent(addThirtyExercise);
        setActiveButton(e);
    };

    function handleShowAddThirtyOneExerciseText(e) {
        setContent(addThirtyOneExercise);
        setActiveButton(e);
    };

    function handleShowAddThirtyTwoExerciseText(e) {
        setContent(addThirtyTwoExercise);
        setActiveButton(e);
    };

    function handleShowAddThirtyThreeExerciseText(e) {
        setContent(addThirtyThreeExercise);
        setActiveButton(e);
    };

    function handleShowAddThirtyFourExerciseText(e) {
        setContent(addThirtyFourExercise);
        setActiveButton(e);
    };

    function handleShowAllExercises(e) {
        setContent(showAllExercises);
        setActiveButton(e);
        toggleReload();
    };

    function handleShowImageBase(e) {
        setContent(showImageBase);
        setActiveButton(e);
        toggleReload();
    };

    const pathToTheExs = collection(database, "exercises");

    useEffect(()=>{
        var array = [];
        const getData = async() => {
            const dbData = await getDocs(pathToTheExs)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            receivedData.map(values => (
                array.push(values.testNumber)
            ));
            const uniqueArray = [...new Set(array)];
            setData(receivedData);
            setNumberOfEx(uniqueArray.reverse());
        }
        getData();
    },[toggle])
    
    return (
        <div>
            <section className="bg-blue-200 bg-center bg-fixed py-3">
                <div className="container mx-auto py-3 text-center">
                    <h1 className="text-black text-4xl font-bold">Кабинет администратора</h1>
                </div>
            </section>
            <section className="bg-blue-200 flex flex-row space-x-1 overflow-x-auto">
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowStudentSubscriptions} data-mainMenuButton="0"  className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Подписки</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowStudentResults} data-mainMenuButton="0"  className="bg-green-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Результаты тестов</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAllExercises} data-mainMenuButton="1"  className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Показать все упражнения</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowImageBase} data-mainMenuButton="38"  className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">База картинок</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddFirstExercise} data-mainMenuButton="2" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 1</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddSecondExercise} data-mainMenuButton="3" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 2</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirdExercise} data-mainMenuButton="4" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 3</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddFourExercise} data-mainMenuButton="5" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 4</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddFifthExercise} data-mainMenuButton="6" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 5</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddSixthExercise} data-mainMenuButton="7" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 6</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddSeventhExercise} data-mainMenuButton="8" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 7</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddEightExercise} data-mainMenuButton="9" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 8</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddNinethExercise} data-mainMenuButton="10" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 9</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTenthExercise} data-mainMenuButton="11" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 10</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddElevenExercise} data-mainMenuButton="12" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 11</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwelveExercise} data-mainMenuButton="13" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 12</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirteenExerciseText} data-mainMenuButton="14" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 13</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddFourteenExercise} data-mainMenuButton="15" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 14</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddFifteenExercise} data-mainMenuButton="17" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 15</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddSixteenExerciseText} data-mainMenuButton="19" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 16</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddSeventeenExerciseText} data-mainMenuButton="20" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 17</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddEighteenExerciseText} data-mainMenuButton="21" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 18</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddNineteenExerciseText} data-mainMenuButton="22" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 19</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyExerciseText} data-mainMenuButton="23" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 20</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyOneExerciseText} data-mainMenuButton="24" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 21</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyTwoExerciseText} data-mainMenuButton="25" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 22</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyThreeExerciseText} data-mainMenuButton="26" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 23</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyFourExerciseText} data-mainMenuButton="27" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 24</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyFiveExerciseText} data-mainMenuButton="28" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 25</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentySixExerciseText} data-mainMenuButton="29" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 26</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentySevenExerciseText} data-mainMenuButton="30" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 27</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyEightExerciseText} data-mainMenuButton="31" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 28</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddTwentyNineExerciseText} data-mainMenuButton="32" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 29</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirtyExerciseText} data-mainMenuButton="33" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 30</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirtyOneExerciseText} data-mainMenuButton="34" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 31</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirtyTwoExerciseText} data-mainMenuButton="35" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 32</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirtyThreeExerciseText} data-mainMenuButton="36" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 33</button>
                </div>
                <div className="container mx-auto py-1 text-center">
                    <button onClick={handleShowAddThirtyFourExerciseText} data-mainMenuButton="37" className="bg-cyan-500 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Создать задание 34</button>
                </div>
            </section>
            {content}
        </div>
    )
}

export default AccountAdmin;

const AddFirstExercise = () => {

    const [questionOne, setQuestionOne] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionOne === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionOne,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 1
            });
            setQuestionOne("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswer.length < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswer.length > 0 && rightAnswer.length < 2) {
                const elementID = "answer" + rightAnswer;
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswer.length === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
    }, [rightAnswer]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        if (allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            for(let i = 0; i<allCheckboxes.length; i++) {
                tempArray.push(allCheckboxes[i].id.slice(0, 1));
            };
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        setRightAnswer(tempArray);
    };
    // console.log(rightAnswer)

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 1</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionOne} onChange={(e) => setQuestionOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddSecondExercise = () => {

    const [questionSecond, setQuestionSecond] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionSecond === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionSecond,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 2
            });
            setQuestionSecond("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 4) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else if(rightAnswerLength === 3) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
                const elementID3 = "answer" + rightAnswer[2];
                const element3 = document.getElementById(elementID3);
                element3.classList.remove("bg-white");
                element3.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 3")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 4) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 3х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(rightAnswer.length);

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 2</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionSecond} onChange={(e) => setQuestionSecond(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ (в нужной последовательности): {rightAnswer.join('-')}
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddThirdExercise = () => {

    const [questionThird, setQuestionThird] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionThird === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionThird,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 3
            });
            setQuestionThird("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswer.length < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswer.length > 0 && rightAnswer.length < 2) {
                const elementID = "answer" + rightAnswer;
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswer.length === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
    }, [rightAnswer]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        if (allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            for(let i = 0; i<allCheckboxes.length; i++) {
                tempArray.push(allCheckboxes[i].id.slice(0, 1));
            };
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        setRightAnswer(tempArray);
    };
    // console.log(rightAnswer)

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 3</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionThird} onChange={(e) => setQuestionThird(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddFourExercise = () => {

    const [questionFour, setQuestionFour] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionFour === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionFour,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 4
            });
            setQuestionFour("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswer.length < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswer.length > 0 && rightAnswer.length < 2) {
                const elementID = "answer" + rightAnswer;
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswer.length === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
    }, [rightAnswer]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        if (allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            for(let i = 0; i<allCheckboxes.length; i++) {
                tempArray.push(allCheckboxes[i].id.slice(0, 1));
            };
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        setRightAnswer(tempArray);
    };
    // console.log(rightAnswer)

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 4</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionFour} onChange={(e) => setQuestionFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddFifthExercise = () => {

    const [questionFifth, setQuestionFifth] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [answerSix, setAnswerSix] = useState("");
    const [answerSeven, setAnswerSeven] = useState("");
    const [answerEight, setAnswerEight] = useState("");
    const [answerNine, setAnswerNine] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionFifth === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(answerSix === ""){
            alert(message)
        } else if(answerSeven === ""){
            alert(message)
        } else if(answerEight === ""){
            alert(message)
        } else if(answerNine === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionFifth,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                answer6: answerSix,
                answer7: answerSeven,
                answer8: answerEight,
                answer9: answerNine,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 5
            });
            setQuestionFifth("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setAnswerSix("");
            setAnswerSeven("");
            setAnswerEight("");
            setAnswerNine("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            const answer6 = document.getElementById('answer6');
            const answer7 = document.getElementById('answer7');
            const answer8 = document.getElementById('answer8');
            const answer9 = document.getElementById('answer9');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            answer6.classList.remove("bg-green-200");
            answer6.classList.add("bg-white");
            answer7.classList.remove("bg-green-200");
            answer7.classList.add("bg-white");
            answer8.classList.remove("bg-green-200");
            answer8.classList.add("bg-white");
            answer9.classList.remove("bg-green-200");
            answer9.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 4) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            const answer6 = document.getElementById('answer6');
            const answer7 = document.getElementById('answer7');
            const answer8 = document.getElementById('answer8');
            const answer9 = document.getElementById('answer9');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            answer6.classList.remove("bg-green-200");
            answer6.classList.add("bg-white");
            answer7.classList.remove("bg-green-200");
            answer7.classList.add("bg-white");
            answer8.classList.remove("bg-green-200");
            answer8.classList.add("bg-white");
            answer9.classList.remove("bg-green-200");
            answer9.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else if(rightAnswerLength === 3) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
                const elementID3 = "answer" + rightAnswer[2];
                const element3 = document.getElementById(elementID3);
                element3.classList.remove("bg-white");
                element3.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 3")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 4) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 3х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(rightAnswer.length);

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 5</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionFifth} onChange={(e) => setQuestionFifth(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="6answercheckbox" name="checkbox" />
                            <input value={answerSix} onChange={(e) => setAnswerSix(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer6" type="text" placeholder="6)"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="7answercheckbox" name="checkbox" />
                            <input value={answerSeven} onChange={(e) => setAnswerSeven(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer7" type="text" placeholder="7)"></input>
                        </div>
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="8answercheckbox" name="checkbox" />
                            <input value={answerEight} onChange={(e) => setAnswerEight(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer8" type="text" placeholder="8)"></input>
                        </div>
                        <div className="w-full md:w-1/3 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="9answercheckbox" name="checkbox" />
                            <input value={answerNine} onChange={(e) => setAnswerNine(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer9" type="text" placeholder="9)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div className="flex flex-row space-x-2">
                        <div>
                            Правильный ответ:
                        </div>
                        <div>
                            <table style={{border: "1px solid black"}}>
                                <thead>
                                    <tr>
                                        <td style={{border: "1px solid black", padding: "8px"}}>
                                            А
                                        </td>
                                        <td style={{border: "1px solid black", padding: "8px"}}>
                                            Б
                                        </td>
                                        <td style={{border: "1px solid black", padding: "8px"}}>
                                            В
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{border: "1px solid black", padding: "8px"}}>
                                            {rightAnswer[0] ? rightAnswer[0] : ""}
                                        </td>
                                        <td style={{border: "1px solid black", padding: "8px"}}>
                                            {rightAnswer[1] ? rightAnswer[1] : ""}
                                        </td>
                                        <td style={{border: "1px solid black", padding: "8px"}}>
                                            {rightAnswer[2] ? rightAnswer[2] : ""}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddSixthExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 6
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 4) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 3х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(rightAnswer.length);

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 6</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        X
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Y
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[0] ? rightAnswer[0] : ""}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[1] ? rightAnswer[1] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddSeventhExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [substanceFour, setSubstanceFour] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [answerD, setAnswerD] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(reagentFour === ""){
            alert(message)
        } else if(reagentFive === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(substanceFour === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                substance4: substanceFour,
                rightAnswer: [answerA, answerB, answerC, answerD],
                testNumber: parseInt(testNumber),
                questionNumber: 7
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setSubstanceFour("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setAnswerD("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 7</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Вещества <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a> / Ответ</h3>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="A"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceFour} onChange={(e) => setSubstanceFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance4" type="text" placeholder="Г)"></input>
                            <input value={answerD} onChange={(e) => setAnswerD(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerD" type="text" placeholder="Г"></input>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Реагенты <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Г
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerD}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddEightExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [reagentSix, setReagentSix] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [answerD, setAnswerD] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [substanceFour, setSubstanceFour] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(reagentFour === ""){
            alert(message)
        } else if(reagentFive === ""){
            alert(message)
        } else if(reagentSix === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(substanceFour === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                reagent6: reagentSix,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                substance4: substanceFour,
                rightAnswer: [answerA, answerB, answerC, answerD],
                testNumber: parseInt(testNumber),
                questionNumber: 8
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setReagentSix("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setAnswerD("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setSubstanceFour("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 8</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Исходные вещества <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a> / Ответ</h3>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="A"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="Б"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="В"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceFour} onChange={(e) => setSubstanceFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance4" type="text" placeholder="Г)"></input>
                            <input value={answerD} onChange={(e) => setAnswerD(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="Г"></input>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Продукты реакции <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                        </div>
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentSix} onChange={(e) => setReagentSix(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent6" type="text" placeholder="6)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Г
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerD}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddNinethExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 9
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 3х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(getDownloadURL(imageRef));


    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 9</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        X
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Y
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[0] ? rightAnswer[0] : ""}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[1] ? rightAnswer[1] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTenthExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(reagentFour === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                rightAnswer: [answerA, answerB, answerC],
                testNumber: parseInt(testNumber),
                questionNumber: 10
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };


    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 10</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Формула вещества <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a> / Ответы</h3>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="A"></input>
                        </div>
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                        </div>
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Класс / группа</h3>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddElevenExercise = () => {

    const [questionOne, setQuestionOne] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionOne === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionOne,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 11
            });
            setQuestionOne("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswer.length < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswer.length > 0 && rightAnswer.length < 2) {
                const elementID = "answer" + rightAnswer;
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswer.length === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
    }, [rightAnswer]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        if (allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            for(let i = 0; i<allCheckboxes.length; i++) {
                tempArray.push(allCheckboxes[i].id.slice(0, 1));
            };
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        setRightAnswer(tempArray);
    };
    // console.log(rightAnswer)

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 11</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionOne} onChange={(e) => setQuestionOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddTwelveExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [questionImageRef, setQuestionImageRef] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [imageFirstRef, setImageFirstRef] = useState("");
    const [imageSecondRef, setImageSecondRef] = useState("");
    const [imageThirdRef, setImageThirdRef] = useState("");
    const [imageFourthRef, setImageFourthRef] = useState("");
    const [imageFifthRef, setImageFifthRef] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить поля: Описание задания, Правильный ответ, Номер теста"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(answerOne === "" && imageFirstRef === ""){
            alert(message)
        } else if(answerTwo === "" && imageSecondRef === ""){
            alert(message)
        } else if(answerThree === "" && imageThirdRef === ""){
            alert(message)
        } else if(answerFour === "" && imageFourthRef === ""){
            alert(message)
        } else if(answerFive === "" && imageFifthRef === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                questionImageRef: questionImageRef,
                imageFirstRef: imageFirstRef,
                imageSecondRef: imageSecondRef,
                imageThirdRef: imageThirdRef,
                imageFourthRef: imageFourthRef,
                imageFifthRef: imageFifthRef,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 12
            });
            setQuestionCurrent("");
            setImageFirstRef("");
            setImageSecondRef("");
            setImageThirdRef("");
            setImageFourthRef("");
            setImageFifthRef("");
            setRightAnswer([]);
            setTestNumber("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setQuestionImageRef("");

            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            setIsLoading(false);
        };
    };

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        setMessageOutOfRange("");
        for(let i = 0; i<allCheckboxes.length; i++) {
            tempArray.push(allCheckboxes[i].id.slice(0, 1));
        };
        setRightAnswer(tempArray);
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 12</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <select onChange={(e) => setQuestionImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка для задания</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                        <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageFirstRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                        <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="2) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageSecondRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                        <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="3) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageThirdRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                        <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="4) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageFourthRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                        <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="5) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageFifthRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ: {rightAnswer.join(", ")}
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddThirteenExercise = () => {

    const [questionFour, setQuestionFour] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [imageFirstRef, setImageFirstRef] = useState("");
    const [imageSecondRef, setImageSecondRef] = useState("");
    const [imageThirdRef, setImageThirdRef] = useState("");
    const [imageFourthRef, setImageFourthRef] = useState("");
    const [imageFifthRef, setImageFifthRef] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionFour === ""){
            alert(message)
        } else if(answerOne === "" && imageFirstRef === ""){
            alert(message)
        } else if(answerTwo === "" && imageSecondRef === ""){
            alert(message)
        } else if(answerThree === "" && imageThirdRef === ""){
            alert(message)
        } else if(answerFour === "" && imageFourthRef === ""){
            alert(message)
        } else if(answerFive === "" && imageFifthRef === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionFour,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                imageFirstRef: imageFirstRef,
                imageSecondRef: imageSecondRef,
                imageThirdRef: imageThirdRef,
                imageFourthRef: imageFourthRef,
                imageFifthRef: imageFifthRef,
                rightAnswer: rightAnswer,
                testNumber: parseInt(testNumber),
                questionNumber: 13
            });
            setQuestionFour("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setImageFirstRef("");
            setImageSecondRef("");
            setImageThirdRef("");
            setImageFourthRef("");
            setImageFifthRef("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            setIsLoading(false);
        };
    };

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        if (allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            for(let i = 0; i<allCheckboxes.length; i++) {
                tempArray.push(allCheckboxes[i].id.slice(0, 1));
            };
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        setRightAnswer(tempArray);
    };
    // console.log(rightAnswer)

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 13</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionFour} onChange={(e) => setQuestionFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                        <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageFirstRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                        <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="2) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageSecondRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                        <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="3) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageThirdRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                        <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="4) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageFourthRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                        <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="ml-1 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="5) Введите текст"></input>
                        <p className="mx-1">или</p>
                        <select onChange={(e) => setImageFifthRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ: {rightAnswer.join(", ")}
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddFourteenExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [reagentSix, setReagentSix] = useState("");
    const [imageFirstRefReagent, setImageFirstRefReagent] = useState("");
    const [imageSecondRefReagent, setImageSecondRefReagent] = useState("");
    const [imageThirdRefReagent, setImageThirdRefReagent] = useState("");
    const [imageFourthRefReagent, setImageFourthRefReagent] = useState("");
    const [imageFifthRefReagent, setImageFifthRefReagent] = useState("");
    const [imageSixRefReagent, setImageSixRefReagent] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [answerD, setAnswerD] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [substanceFour, setSubstanceFour] = useState("");
    const [imageFirstRefSubstance, setImageFirstRefSubstance] = useState("");
    const [imageSecondRefSubstance, setImageSecondRefSubstance] = useState("");
    const [imageThirdRefSubstance, setImageThirdRefSubstance] = useState("");
    const [imageFourthRefSubstance, setImageFourthRefSubstance] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [nameOfSubstance, setNameOfSubstance] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === "" && imageFirstRefReagent === ""){
            alert(message)
        } else if(reagentTwo === "" && imageSecondRefReagent === ""){
            alert(message)
        } else if(reagentThree === "" && imageThirdRefReagent === ""){
            alert(message)
        } else if(reagentFour === "" && imageFourthRefReagent === ""){
            alert(message)
        } else if(reagentFive === "" && imageFifthRefReagent === ""){
            alert(message)
        } else if(reagentSix === "" && imageSixRefReagent === ""){
            alert(message)
        } else if(nameOfReagent === ""){
            alert(message)
        } else if(nameOfSubstance === ""){
            alert(message)
        } else if(substanceOne === "" && imageFirstRefSubstance === ""){
            alert(message)
        } else if(substanceTwo === "" && imageSecondRefSubstance === ""){
            alert(message)
        } else if(substanceThree === "" && imageThirdRefSubstance === ""){
            alert(message)
        } else if(substanceFour === "" && imageFourthRefSubstance === ""){
            alert(message)
        } else if(answerA === ""){
            alert(message)
        } else if(answerB === ""){
            alert(message)
        } else if(answerC === ""){
            alert(message)
        } else if(answerD === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                reagent6: reagentSix,
                imageFirstRefReagent: imageFirstRefReagent,
                imageSecondRefReagent: imageSecondRefReagent,
                imageThirdRefReagent: imageThirdRefReagent,
                imageFourthRefReagent: imageFourthRefReagent,
                imageFifthRefReagent: imageFifthRefReagent,
                imageSixRefReagent: imageSixRefReagent,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                substance4: substanceFour,
                imageFirstRefSubstance: imageFirstRefSubstance,
                imageSecondRefSubstance: imageSecondRefSubstance,
                imageThirdRefSubstance: imageThirdRefSubstance,
                imageFourthRefSubstance: imageFourthRefSubstance,
                nameOfReagent: nameOfReagent,
                nameOfSubstance: nameOfSubstance,
                rightAnswer: [answerA, answerB, answerC, answerD],
                testNumber: parseInt(testNumber),
                questionNumber: 14
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setReagentSix("");
            setImageFirstRefReagent("");
            setImageSecondRefReagent("");
            setImageThirdRefReagent("");
            setImageFourthRefReagent("");
            setImageFifthRefReagent("");
            setImageSixRefReagent("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setSubstanceFour("");
            setImageFirstRefSubstance("");
            setImageSecondRefSubstance("");
            setImageThirdRefSubstance("");
            setImageFourthRefSubstance("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setAnswerD("");
            setRightAnswer([]);
            setTestNumber("");
            setNameOfReagent("");
            setNameOfSubstance("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 14</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1 / Ответ</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFirstRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="А"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageSecondRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageThirdRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceFour} onChange={(e) => setSubstanceFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance4" type="text" placeholder="Г)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFourthRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerD} onChange={(e) => setAnswerD(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerD" type="text" placeholder="Г"></input>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="flex flex-row mx-1 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFirstRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageSecondRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageThirdRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFourthRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFifthRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentSix} onChange={(e) => setReagentSix(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent6" type="text" placeholder="6)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageSixRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Г
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerD}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddFifteenExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [reagentSix, setReagentSix] = useState("");
    const [imageFirstRefReagent, setImageFirstRefReagent] = useState("");
    const [imageSecondRefReagent, setImageSecondRefReagent] = useState("");
    const [imageThirdRefReagent, setImageThirdRefReagent] = useState("");
    const [imageFourthRefReagent, setImageFourthRefReagent] = useState("");
    const [imageFifthRefReagent, setImageFifthRefReagent] = useState("");
    const [imageSixRefReagent, setImageSixRefReagent] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [answerD, setAnswerD] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [substanceFour, setSubstanceFour] = useState("");
    const [imageFirstRefSubstance, setImageFirstRefSubstance] = useState("");
    const [imageSecondRefSubstance, setImageSecondRefSubstance] = useState("");
    const [imageThirdRefSubstance, setImageThirdRefSubstance] = useState("");
    const [imageFourthRefSubstance, setImageFourthRefSubstance] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [nameOfSubstance, setNameOfSubstance] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === "" && imageFirstRefReagent === ""){
            alert(message)
        } else if(reagentTwo === "" && imageSecondRefReagent === ""){
            alert(message)
        } else if(reagentThree === "" && imageThirdRefReagent === ""){
            alert(message)
        } else if(reagentFour === "" && imageFourthRefReagent === ""){
            alert(message)
        } else if(reagentFive === "" && imageFifthRefReagent === ""){
            alert(message)
        } else if(nameOfReagent === ""){
            alert(message)
        } else if(nameOfSubstance === ""){
            alert(message)
        } else if(substanceOne === "" && imageFirstRefSubstance === ""){
            alert(message)
        } else if(substanceTwo === "" && imageSecondRefSubstance === ""){
            alert(message)
        } else if(substanceThree === "" && imageThirdRefSubstance === ""){
            alert(message)
        } else if(substanceFour === "" && imageFourthRefSubstance === ""){
            alert(message)
        } else if(answerA === ""){
            alert(message)
        } else if(answerB === ""){
            alert(message)
        } else if(answerC === ""){
            alert(message)
        } else if(answerD === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                reagent6: reagentSix,
                imageFirstRefReagent: imageFirstRefReagent,
                imageSecondRefReagent: imageSecondRefReagent,
                imageThirdRefReagent: imageThirdRefReagent,
                imageFourthRefReagent: imageFourthRefReagent,
                imageFifthRefReagent: imageFifthRefReagent,
                imageSixRefReagent: imageSixRefReagent,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                substance4: substanceFour,
                imageFirstRefSubstance: imageFirstRefSubstance,
                imageSecondRefSubstance: imageSecondRefSubstance,
                imageThirdRefSubstance: imageThirdRefSubstance,
                imageFourthRefSubstance: imageFourthRefSubstance,
                nameOfReagent: nameOfReagent,
                nameOfSubstance: nameOfSubstance,
                rightAnswer: [answerA, answerB, answerC, answerD],
                testNumber: parseInt(testNumber),
                questionNumber: 15
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setReagentSix("");
            setImageFirstRefReagent("");
            setImageSecondRefReagent("");
            setImageThirdRefReagent("");
            setImageFourthRefReagent("");
            setImageFifthRefReagent("");
            setImageSixRefReagent("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setSubstanceFour("");
            setImageFirstRefSubstance("");
            setImageSecondRefSubstance("");
            setImageThirdRefSubstance("");
            setImageFourthRefSubstance("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setAnswerD("");
            setRightAnswer([]);
            setTestNumber("");
            setNameOfReagent("");
            setNameOfSubstance("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 15</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a> / Ответ</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFirstRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="А"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageSecondRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageThirdRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceFour} onChange={(e) => setSubstanceFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance4" type="text" placeholder="Г)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFourthRefSubstance(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerD} onChange={(e) => setAnswerD(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerD" type="text" placeholder="Г"></input>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="flex flex-row mx-1 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFirstRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageSecondRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageThirdRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFourthRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageFifthRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                            <div className="w-full md:w-2/2 mb-6 md:mb-0 flex items-center">
                                <input value={reagentSix} onChange={(e) => setReagentSix(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent6" type="text" placeholder="6)"></input>
                            </div>
                            <p className="mx-1">или</p>
                            <select onChange={(e) => setImageSixRefReagent(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                                {dataImage.map(image => 
                                    {return <option value={image.imageRef}>
                                        {image.picName}
                                    </option>})}
                            </select>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Г
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerD}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddSixteenExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 16
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(getDownloadURL(imageRef));

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 16</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                        {dataImage.map(image => 
                            {return <option value={image.imageRef}>
                                {image.picName}
                            </option>})}
                        </select>
                    </div>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        X
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Y
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[0] ? rightAnswer[0] : ""}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[1] ? rightAnswer[1] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddSeventeenExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [answerSix, setAnswerSix] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(answerSix === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                answer6: answerSix,
                rightAnswer: rightAnswer,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 17
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setAnswerSix("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            const answer6 = document.getElementById('answer6');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            answer6.classList.remove("bg-green-200");
            answer6.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        setMessageOutOfRange("");
        for(let i = 0; i<allCheckboxes.length; i++) {
            tempArray.push(allCheckboxes[i].id.slice(0, 1));
        };
        setRightAnswer(tempArray);
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 17</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <h3 className="text-center font-semibold">Ответы <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="6answercheckbox" name="checkbox" />
                            <input value={answerSix} onChange={(e) => setAnswerSix(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer6" type="text" placeholder="6)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ: {rightAnswer.join(", ")}
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddEighteenExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                rightAnswer: rightAnswer,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 18
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = [];
        setMessageOutOfRange("");
        for(let i = 0; i<allCheckboxes.length; i++) {
            tempArray.push(allCheckboxes[i].id.slice(0, 1));
        };
        setRightAnswer(tempArray);
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 18</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ: {rightAnswer.join(", ")}
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddNineteenExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [nameOfSubstance, setNameOfSubstance] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(reagentFour === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                nameOfReagent: nameOfReagent,
                nameOfSubstance: nameOfSubstance,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                rightAnswer: [answerA, answerB, answerC],
                testNumber: parseInt(testNumber),
                questionNumber: 19
            });
            setQuestionCurrent("");
            setNameOfReagent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setNameOfSubstance("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 19</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="A"></input>
                        </div>
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                        </div>
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="В"></input>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 2"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2">
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTwentyExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [nameOfSubstance, setNameOfSubstance] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(reagentFour === ""){
            alert(message)
        } else if(reagentFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                nameOfReagent: nameOfReagent,
                nameOfSubstance: nameOfSubstance,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                rightAnswer: [answerA, answerB, answerC],
                testNumber: parseInt(testNumber),
                questionNumber: 20
            });
            setQuestionCurrent("");
            setNameOfReagent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setNameOfSubstance("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 20</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a>/ Ответы</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="A"></input>
                        </div>
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                        </div>
                        <div className="w-full md:w-3/3 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 2"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2">
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-1 flex items-center">
                            <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTwentyOneExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                rightAnswer: rightAnswer,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 21
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength === 4) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else if(rightAnswerLength === 3) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
                const elementID3 = "answer" + rightAnswer[2];
                const element3 = document.getElementById(elementID3);
                element3.classList.remove("bg-white");
                element3.classList.add("bg-green-200");
            } else if(rightAnswerLength === 4) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
                const elementID3 = "answer" + rightAnswer[2];
                const element3 = document.getElementById(elementID3);
                element3.classList.remove("bg-white");
                element3.classList.add("bg-green-200");
                const elementID4 = "answer" + rightAnswer[3];
                const element4 = document.getElementById(elementID4);
                element4.classList.remove("bg-white");
                element4.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов меньше 4")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 5) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 4х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(getDownloadURL(imageRef));

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 21</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-col mx-0 mb-2">
                        {/* <div className="text-right">
                            <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a>
                        </div> */}
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div className="flex flex-row items-center space-x-1">
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {rightAnswer[0] ? rightAnswer[0] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>→</p>
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {rightAnswer[1] ? rightAnswer[1] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>→</p>
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {rightAnswer[2] ? rightAnswer[2] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>→</p>
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {rightAnswer[3] ? rightAnswer[3] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTwentyTwoExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [nameOfSubstance, setNameOfSubstance] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [answerD, setAnswerD] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [substanceFour, setSubstanceFour] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(answerA === ""){
            alert(message)
        } else if(answerB === ""){
            alert(message)
        } else if(answerC === ""){
            alert(message)
        } else if(answerD === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(substanceFour === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                substance4: substanceFour,
                nameOfReagent: nameOfReagent,
                nameOfSubstance: nameOfSubstance,
                rightAnswer: [answerA, answerB, answerC, answerD],
                testNumber: parseInt(testNumber),
                questionNumber: 22
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setSubstanceFour("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setAnswerD("");
            setNameOfSubstance("");
            setNameOfReagent("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 5) {
            const reagent1 = document.getElementById('reagent1');
            const reagent2 = document.getElementById('reagent2');
            const reagent3 = document.getElementById('reagent3');
            reagent1.classList.remove("bg-green-200");
            reagent1.classList.add("bg-white");
            reagent2.classList.remove("bg-green-200");
            reagent2.classList.add("bg-white");
            reagent3.classList.remove("bg-green-200");
            reagent3.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "reagent" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "reagent" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "reagent" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else if(rightAnswerLength === 3) {
                const elementID = "reagent" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "reagent" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
                const elementID3 = "reagent" + rightAnswer[2];
                const element3 = document.getElementById(elementID3);
                element3.classList.remove("bg-white");
                element3.classList.add("bg-green-200");
            } else if(rightAnswerLength === 4) {
                const elementID = "reagent" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "reagent" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
                const elementID3 = "reagent" + rightAnswer[2];
                const element3 = document.getElementById(elementID3);
                element3.classList.remove("bg-white");
                element3.classList.add("bg-green-200");
                const elementID4 = "reagent" + rightAnswer[3];
                const element4 = document.getElementById(elementID4);
                element4.classList.remove("bg-white");
                element4.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 4")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 22</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a>
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a> / Ответы</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="А"></input>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceFour} onChange={(e) => setSubstanceFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance4" type="text" placeholder="Г)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerD} onChange={(e) => setAnswerD(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerD" type="text" placeholder="Г"></input>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 2"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-2/2 px-1 mb-1 md:mb-0 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-2/2 px-1 mb-1 md:mb-0 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-2/2 px-1 mb-1 md:mb-0 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Г
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerD}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTwentyThreeExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [answerOne, setAnswerOne] = useState("");
    const [answerTwo, setAnswerTwo] = useState("");
    const [answerThree, setAnswerThree] = useState("");
    const [answerFour, setAnswerFour] = useState("");
    const [answerFive, setAnswerFive] = useState("");
    const [answerSix, setAnswerSix] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [rightAnswerLength, setRightAnswerLength] = useState();
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerOne === ""){
            alert(message)
        } else if(answerTwo === ""){
            alert(message)
        } else if(answerThree === ""){
            alert(message)
        } else if(answerFour === ""){
            alert(message)
        } else if(answerFive === ""){
            alert(message)
        } else if(answerSix === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                answer1: answerOne,
                answer2: answerTwo,
                answer3: answerThree,
                answer4: answerFour,
                answer5: answerFive,
                answer6: answerSix,
                rightAnswer: rightAnswer,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 23
            });
            setQuestionCurrent("");
            setAnswerOne("");
            setAnswerTwo("");
            setAnswerThree("");
            setAnswerFour("");
            setAnswerFive("");
            setAnswerSix("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
            for(let i = 0; i<allCheckboxes.length; i++) {
                allCheckboxes[i].checked = false;
            };
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            const answer6 = document.getElementById('answer6');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            answer6.classList.remove("bg-green-200");
            answer6.classList.add("bg-white");
            setIsLoading(false);
        };
    };

    useEffect(() => {
        if (rightAnswer !== undefined && rightAnswerLength < 3) {
            const answer1 = document.getElementById('answer1');
            const answer2 = document.getElementById('answer2');
            const answer3 = document.getElementById('answer3');
            const answer4 = document.getElementById('answer4');
            const answer5 = document.getElementById('answer5');
            const answer6 = document.getElementById('answer6');
            answer1.classList.remove("bg-green-200");
            answer1.classList.add("bg-white");
            answer2.classList.remove("bg-green-200");
            answer2.classList.add("bg-white");
            answer3.classList.remove("bg-green-200");
            answer3.classList.add("bg-white");
            answer4.classList.remove("bg-green-200");
            answer4.classList.add("bg-white");
            answer5.classList.remove("bg-green-200");
            answer5.classList.add("bg-white");
            answer6.classList.remove("bg-green-200");
            answer6.classList.add("bg-white");
            if(rightAnswerLength > 0 && rightAnswerLength < 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
            } else if(rightAnswerLength === 2) {
                const elementID = "answer" + rightAnswer[0];
                const element = document.getElementById(elementID);
                element.classList.remove("bg-white");
                element.classList.add("bg-green-200");
                const elementID2 = "answer" + rightAnswer[1];
                const element2 = document.getElementById(elementID2);
                element2.classList.remove("bg-white");
                element2.classList.add("bg-green-200");
            } else {
                console.log("правильных ответов больше 2")
            }
        }
        // console.log(rightAnswer.length);
    }, [rightAnswerLength]);

    const checkboxAnswer = (e) => {
        var allCheckboxes = document.querySelectorAll('input[name="checkbox"]:checked');
        var tempArray = rightAnswer;
        if(tempArray.includes(e.target.id.slice(0, 1))){
            const index = tempArray.indexOf(e.target.id.slice(0, 1));
            tempArray.splice(index, 1);
            setRightAnswer(tempArray);
        } else {
            tempArray.push(e.target.id.slice(0, 1));
            setRightAnswer(tempArray);
        }
        if (allCheckboxes.length > 0 && allCheckboxes.length < 3) {
            setMessageOutOfRange("");
            // console.log(e.target.id.slice(0, 1))
        } else {
            setMessageOutOfRange("Должно быть НЕ более 2х правильных ответов")
        }
        // setRightAnswer(tempArray);
        setRightAnswerLength(rightAnswer.length)
    };
    // console.log(getDownloadURL(imageRef));

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 23</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-col mx-0 mb-2">
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="1answercheckbox" name="checkbox" />
                            <input value={answerOne} onChange={(e) => setAnswerOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="2answercheckbox" name="checkbox" />
                            <input value={answerTwo} onChange={(e) => setAnswerTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="3answercheckbox" name="checkbox" />
                            <input value={answerThree} onChange={(e) => setAnswerThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="4answercheckbox" name="checkbox" />
                            <input value={answerFour} onChange={(e) => setAnswerFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="5answercheckbox" name="checkbox" />
                            <input value={answerFive} onChange={(e) => setAnswerFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="text" placeholder="5)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                        <input type="checkbox" onChange={checkboxAnswer} className="w-4 h-4 mr-2" id="6answercheckbox" name="checkbox" />
                            <input value={answerSix} onChange={(e) => setAnswerSix(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer6" type="text" placeholder="6)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        X
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Y
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[0] ? rightAnswer[0] : ""}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {rightAnswer[1] ? rightAnswer[1] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddTwentyFourExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [nameOfSubstance, setNameOfSubstance] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [substanceFour, setSubstanceFour] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [answerD, setAnswerD] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(reagentFour === ""){
            alert(message)
        } else if(reagentFive === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(substanceFour === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                substance4: substanceFour,
                nameOfReagent: nameOfReagent,
                nameOfSubstance: nameOfSubstance,
                rightAnswer: [answerA, answerB, answerC, answerD],
                testNumber: parseInt(testNumber),
                questionNumber: 24
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setSubstanceFour("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setAnswerD("");
            setNameOfSubstance("");
            setNameOfReagent("");
            setRightAnswer([]);
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 24</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a> / Ответы</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 1"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="A"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                        </div>
                        <div className="w-full md:w-4/4 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            <input value={substanceFour} onChange={(e) => setSubstanceFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance4" type="text" placeholder="Г)"></input>
                            <input value={answerD} onChange={(e) => setAnswerD(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerD" type="text" placeholder="Г"></input>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2 <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a></h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 2"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2">
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-5/5 px-1 mb-1 flex items-center">
                            <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Г
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerD}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTwentyFiveExercise = () => {

    const [questionCurrent, setQuestionCurrent] = useState("");
    const [reagentOne, setReagentOne] = useState("");
    const [reagentTwo, setReagentTwo] = useState("");
    const [reagentThree, setReagentThree] = useState("");
    const [reagentFour, setReagentFour] = useState("");
    const [reagentFive, setReagentFive] = useState("");
    const [substanceOne, setSubstanceOne] = useState("");
    const [answerA, setAnswerA] = useState("");
    const [answerB, setAnswerB] = useState("");
    const [answerC, setAnswerC] = useState("");
    const [substanceTwo, setSubstanceTwo] = useState("");
    const [substanceThree, setSubstanceThree] = useState("");
    const [rightAnswer, setRightAnswer] = useState([]);
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [nameOfSubstance, setNameOfSubstance] = useState("");
    const [nameOfReagent, setNameOfReagent] = useState("");
    const [messageOutOfRange, setMessageOutOfRange] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateCurrentEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(questionCurrent === ""){
            alert(message)
        } else if(answerA === ""){
            alert(message)
        } else if(answerB === ""){
            alert(message)
        } else if(answerC === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else if(reagentOne === ""){
            alert(message)
        } else if(reagentTwo === ""){
            alert(message)
        } else if(reagentThree === ""){
            alert(message)
        } else if(substanceOne === ""){
            alert(message)
        } else if(substanceTwo === ""){
            alert(message)
        } else if(substanceThree === ""){
            alert(message)
        } else if(nameOfSubstance === ""){
            alert(message)
        } else if(nameOfReagent === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: questionCurrent,
                nameOfReagent: nameOfReagent,
                reagent1: reagentOne,
                reagent2: reagentTwo,
                reagent3: reagentThree,
                reagent4: reagentFour,
                reagent5: reagentFive,
                nameOfSubstance: nameOfSubstance,
                substance1: substanceOne,
                substance2: substanceTwo,
                substance3: substanceThree,
                rightAnswer: [answerA, answerB, answerC],
                testNumber: parseInt(testNumber),
                questionNumber: 25
            });
            setQuestionCurrent("");
            setReagentOne("");
            setReagentTwo("");
            setReagentThree("");
            setReagentFour("");
            setReagentFive("");
            setSubstanceOne("");
            setSubstanceTwo("");
            setSubstanceThree("");
            setAnswerA("");
            setAnswerB("");
            setAnswerC("");
            setRightAnswer([]);
            setTestNumber("");
            setNameOfSubstance("");
            setNameOfReagent("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 25</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={questionCurrent} onChange={(e) => setQuestionCurrent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <h3 className="text-center font-semibold">Группа 1</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfSubstance} onChange={(e) => setNameOfSubstance(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 2"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceOne} onChange={(e) => setSubstanceOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="А)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerA} onChange={(e) => setAnswerA(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerA" type="text" placeholder="А"></input>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceTwo} onChange={(e) => setSubstanceTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance2" type="text" placeholder="Б)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerB} onChange={(e) => setAnswerB(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerB" type="text" placeholder="Б"></input>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full md:w-4/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={substanceThree} onChange={(e) => setSubstanceThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance3" type="text" placeholder="В)"></input>
                            </div>
                            <div className="w-full md:w-1/5 px-1 mb-6 md:mb-0 flex items-center">
                                <input value={answerC} onChange={(e) => setAnswerC(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answerC" type="text" placeholder="В"></input>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-center font-semibold">Группа 2</h3>
                    <div className="w-full md:w-5/5 px-1 mb-6 mb-1 flex items-center">
                        <input value={nameOfReagent} onChange={(e) => setNameOfReagent(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="substance1" type="text" placeholder="Название группы 2"></input>
                    </div>
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full md:w-2/2 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentOne} onChange={(e) => setReagentOne(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent1" type="text" placeholder="1)"></input>
                        </div>
                        <div className="w-full md:w-2/2 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentTwo} onChange={(e) => setReagentTwo(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent2" type="text" placeholder="2)"></input>
                        </div>
                        <div className="w-full md:w-2/2 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentThree} onChange={(e) => setReagentThree(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent3" type="text" placeholder="3)"></input>
                        </div>
                        <div className="w-full md:w-2/2 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFour} onChange={(e) => setReagentFour(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent4" type="text" placeholder="4)"></input>
                        </div>
                        <div className="w-full md:w-2/2 px-1 mb-6 md:mb-0 flex items-center">
                            <input value={reagentFive} onChange={(e) => setReagentFive(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="reagent5" type="text" placeholder="5)"></input>
                        </div>
                    </div>
                    <div className="text-center bg-red-300">{messageOutOfRange}</div>
                    <div>
                        Правильный ответ:
                    </div>
                    <div>
                        <table style={{border: "1px solid black"}}>
                            <thead>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        А
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        Б
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        В
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerA}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerB}
                                    </td>
                                    <td style={{border: "1px solid black", padding: "8px"}}>
                                        {answerC}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer5" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateCurrentEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddTwentySixExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");
    const [measuring, setMeasuring] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(measuring === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                rightAnswer: rightAnswer,
                measuring: measuring,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 26
            });
            setCurrentQuestion("");
            setRightAnswer("");
            setTestNumber("");
            setMeasuring("");
            setImageRef("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 26</h2>
                <div className="w-full max-w-3xl container mx-auto">
                    <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                        {dataImage.map(image => 
                            {return <option value={image.imageRef}>
                                {image.picName}
                            </option>})}
                    </select>
                </div>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            Ответ: <input value={rightAnswer} onChange={(e) => setRightAnswer(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer" type="text" placeholder="Введите число"></input><input value={measuring} onChange={(e) => setMeasuring(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="measuring" type="text" placeholder="Введите единицу измерения"></input>
                        </div>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddTwentySevenExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");
    const [measuring, setMeasuring] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");
    const [imageRefTask, setImageRefTask] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(measuring === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                rightAnswer: rightAnswer,
                measuring: measuring,
                imageRef: imageRef,
                imageRefTask: imageRefTask,
                testNumber: parseInt(testNumber),
                questionNumber: 27
            });
            setCurrentQuestion("");
            setRightAnswer("");
            setTestNumber("");
            setMeasuring("");
            setImageRefTask("");
            setImageRef("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 27</h2>
                {/* <div className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        Текст задания 26-28:
                        <select onChange={(e) => setImageRefTask(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                </div> */}
                <form className="w-full max-w-3xl container mx-auto">
                    {/* <div className="flex flex-row mx-1 mb-2 items-center justify-between">
                        Формула:
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div> */}
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания <a id="React-tooltip" className="bg-orange-500 px-1 rounded text-white font-black text-lg">@</a>
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            Ответ: <input value={rightAnswer} onChange={(e) => setRightAnswer(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer" type="text" placeholder="Введите число"></input><input value={measuring} onChange={(e) => setMeasuring(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="measuring" type="text" placeholder="Введите единицу измерения"></input>
                        </div>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <Tooltip anchorSelect="#React-tooltip" type='warning' effect='solid'>
                <div>
                    <h2>Здесь необходимо скорректировать данные!</h2>
                    <p>Перед цифрами и знаками '+' и '-', НЕ являющимися индексами необходимо ставить знак @</p>
                    <p>Таким образом программа не будет переводить их в положение индекса или в степень</p>
                    <p>Пример:</p>
                    <p>Исходное значение: 2SO2(г) + O2(г) ⇄ 2SO3(г) + Q</p>
                    <p>Скорректировано: @2SO2(г) @+ O2(г) ⇄ @2SO3(г) @+ Q</p>
                </div>
            </Tooltip>
        </div>
    )
};

const AddTwentyEightExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [rightAnswer, setRightAnswer] = useState("");
    const [measuring, setMeasuring] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(rightAnswer === ""){
            alert(message)
        } else if(measuring === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                rightAnswer: rightAnswer,
                measuring: measuring,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 28
            });
            setCurrentQuestion("");
            setRightAnswer("");
            setTestNumber("");
            setMeasuring("");
            setImageRef("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 28</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex flex-wrap mx-0 mb-2">
                        <div className="w-full md:w-5/5 px-1 mb-6 md:mb-0 flex items-center space-x-1">
                            Ответ: <input value={rightAnswer} onChange={(e) => setRightAnswer(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer" type="text" placeholder="Введите число"></input><input value={measuring} onChange={(e) => setMeasuring(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="measuring" type="text" placeholder="Введите единицу измерения"></input>
                        </div>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddTwentyNineExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 29
            });
            setCurrentQuestion("");
            setTestNumber("");
            setImageRef("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 29</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddThirtyExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 30
            });
            setCurrentQuestion("");
            setTestNumber("");
            setImageRef("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 30</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddThirtyOneExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                testNumber: parseInt(testNumber),
                questionNumber: 31
            });
            setCurrentQuestion("");
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 31</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddThirtyTwoExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();
    const [imageRef, setImageRef] = useState("");

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                imageRef: imageRef,
                testNumber: parseInt(testNumber),
                questionNumber: 32
            });
            setCurrentQuestion("");
            setTestNumber("");
            setImageRef("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    const [dataImage, setDataImage] = useState([]);
    const pathToImageBase = collection(database, "images");

    useEffect(()=>{
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            setDataImage(receivedData);
        }
        getData();
    },[])

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 32</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2 items-center justify-between">
                        <select onChange={(e) => setImageRef(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option hidden disabled selected value='0'>Выберите картинку из списка</option>
                            {dataImage.map(image => 
                                {return <option value={image.imageRef}>
                                    {image.picName}
                                </option>})}
                        </select>
                    </div>
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddThirtyThreeExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                testNumber: parseInt(testNumber),
                questionNumber: 33
            });
            setCurrentQuestion("");
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 33</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const AddThirtyFourExercise = () => {

    const [currentQuestion, setCurrentQuestion] = useState("");
    const [testNumber, setTestNumber] = useState("");
    const [isLoading, setIsLoading] = useState();

    const pathToFirstExecrises = collection(database, "exercises");

    const hadleCreateFirstEx = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(currentQuestion === ""){
            alert(message)
        } else if(testNumber === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToFirstExecrises, {
                question: currentQuestion,
                testNumber: parseInt(testNumber),
                questionNumber: 34
            });
            setCurrentQuestion("");
            setTestNumber("");
            await updateDoc(doc(pathToFirstExecrises, documentId), {
                id: documentId
            });
            setIsLoading(false);
        };
    };

    return (
        <div>
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Задание 34</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-wrap mx-1 mb-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Описание задания
                        </label>
                        <textarea rows={4} value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Введите текст задания"></textarea>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                    <input value={testNumber} onChange={(e) => setTestNumber(e.target.value)} className="w-full md:w-1/5 appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="answer0" type="number" placeholder="№ варианта"></input>
                        <button onClick={hadleCreateFirstEx} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
        </div>
    )
};

const ShowAllExercises = ({data, numberOfEx}) => {

    const [currentTestFirstEx, setCurrentTestFirstEx] = useState([]);
    const [currentTestSecondEx, setCurrentTestSecondEx] = useState([]);
    const [currentTestThirdEx, setCurrentTestThirdEx] = useState([]);
    const [currentTestFourthEx, setCurrentTestFourthEx] = useState([]);
    const [currentTestFifthEx, setCurrentTestFifthEx] = useState([]);
    const [currentTestSixthEx, setCurrentTestSixthEx] = useState([]);
    const [currentTestSeventhEx, setCurrentTestSeventhEx] = useState([]);
    const [currentTestEightEx, setCurrentTestEightEx] = useState([]);
    const [currentTestNinethEx, setCurrentTestNinethEx] = useState([]);
    const [currentTestTenthEx, setCurrentTestTenthEx] = useState([]);
    const [currentTestElevenhEx, setCurrentTestElevenEx] = useState([]);
    const [currentTestTwelveEx, setCurrentTestTwelveEx] = useState([]);
    const [currentTestThirteenEx, setCurrentTestThirteenEx] = useState([]);
    const [currentTestFourteenEx, setCurrentTestFourteenEx] = useState([]);
    const [currentTestFifteenEx, setCurrentTestFifteenEx] = useState([]);
    const [currentTestSixteenEx, setCurrentTestSixteenEx] = useState([]);
    const [currentTestSeventeenEx, setCurrentTestSeventeenEx] = useState([]);
    const [currentTestEighteenEx, setCurrentTestEighteenEx] = useState([]);
    const [currentTestNineteenEx, setCurrentTestNineteenEx] = useState([]);
    const [currentTestTwentyEx, setCurrentTestTwentyEx] = useState([]);
    const [currentTestTwentyOneEx, setCurrentTestTwentyOneEx] = useState([]);
    const [currentTestTwentyTwoEx, setCurrentTestTwentyTwoEx] = useState([]);
    const [currentTestTwentyThreeEx, setCurrentTestTwentyThreeEx] = useState([]);
    const [currentTestTwentyFourEx, setCurrentTestTwentyFourEx] = useState([]);
    const [currentTestTwentyFiveEx, setCurrentTestTwentyFiveEx] = useState([]);
    const [currentTestTwentySixEx, setCurrentTestTwentySixEx] = useState([]);
    const [currentTestTwentySevenEx, setCurrentTestTwentySevenEx] = useState([]);
    const [currentTestTwentyEightEx, setCurrentTestTwentyEightEx] = useState([]);
    const [currentTestTwentyNineEx, setCurrentTestTwentyNineEx] = useState([]);
    const [currentTestThirtyEx, setCurrentTestThirtyEx] = useState([]);
    const [currentTestThirtyOneEx, setCurrentTestThirtyOneEx] = useState([]);
    const [currentTestThirtyTwoEx, setCurrentTestThirtyTwoEx] = useState([]);
    const [currentTestThirtyThreeEx, setCurrentTestThirtyThreeEx] = useState([]);
    const [currentTestThirtyFourEx, setCurrentTestThirtyFourEx] = useState([]);
    
    function containsNumber(str) {
        var newArrayForString = [];
        if(str !== undefined){
            for (let i = 0; i < str.length; i++) {
                if(/\d/.test(str[i]) === true) {
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else if(str[i] === '0') {
                        newArrayForString.push("<sup>"+str[i]+"</sup>");
                    } else {
                        newArrayForString.push("<sub>"+str[i]+"</sub>");
                    }
                } else if(str[i] === '@') {
                    // newArrayForString.push("");
                } else if(str[i] === '+'){
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push("<sup>"+str[i]+"</sup>");
                    };
                } else if(str[i] === '-'){
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push("<sup>"+str[i]+"</sup>");
                    };
                } else {
                    newArrayForString.push(str[i]);
                };
            };
            return newArrayForString.join('');
        } else {
            return str;
        };
        // Check if the string contains any digit between 0 and 9
    };

    const showTestByNumber = (e) => {
        var buttons = document.querySelectorAll('[data-numberOfExButton]');
        for(let i = 0; i<buttons.length; i++) {
            buttons[i].classList.remove("bg-orange-700");
            buttons[i].classList.add("bg-blue-500");
        };
        e.target.classList.remove("bg-blue-500");
        e.target.classList.add("bg-orange-700");
        setCurrentTestFirstEx([]);
        setCurrentTestSecondEx([]);
        setCurrentTestThirdEx([]);
        setCurrentTestFourthEx([]);
        setCurrentTestFifthEx([]);
        setCurrentTestSixthEx([]);
        setCurrentTestSeventhEx([]);
        setCurrentTestEightEx([]);
        setCurrentTestNinethEx([]);
        setCurrentTestTenthEx([]);
        setCurrentTestElevenEx([]);
        setCurrentTestTwelveEx([]);
        setCurrentTestThirteenEx([]);
        setCurrentTestFourteenEx([]);
        setCurrentTestFifteenEx([]);
        setCurrentTestSixteenEx([]);
        setCurrentTestSeventeenEx([]);
        setCurrentTestEighteenEx([]);
        setCurrentTestNineteenEx([]);
        setCurrentTestTwentyEx([]);
        setCurrentTestTwentyOneEx([]);
        setCurrentTestTwentyTwoEx([]);
        setCurrentTestTwentyThreeEx([]);
        setCurrentTestTwentyFourEx([]);
        setCurrentTestTwentyFiveEx([]);
        setCurrentTestTwentySixEx([]);
        setCurrentTestTwentySevenEx([]);
        setCurrentTestTwentyEightEx([]);
        setCurrentTestTwentyNineEx([]);
        setCurrentTestThirtyEx([]);
        setCurrentTestThirtyOneEx([]);
        setCurrentTestThirtyTwoEx([]);
        setCurrentTestThirtyThreeEx([]);
        setCurrentTestThirtyFourEx([]);
        data.map(values => {
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 1) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestFirstEx(tempObj);
            }
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 2) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestSecondEx(tempObj);
            }
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 3) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestThirdEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 4) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestFourthEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 5) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, answer6: values.answer6, answer7: values.answer7, answer8: values.answer8, answer9: values.answer9, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestFifthEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 6) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestSixthEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 7) {
                let tempObj = {question: values.question, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestSeventhEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 8) {
                let tempObj = {question: values.question, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestEightEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 9) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                setCurrentTestNinethEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 10) {
                let tempObj = {question: values.question, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestTenthEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 11) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestElevenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 12) {
                let tempObj = {question: values.question, questionImageRef: values.questionImageRef, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, imageFirstRef: values.imageFirstRef, imageSecondRef: values.imageSecondRef, imageThirdRef: values.imageThirdRef, imageFourthRef: values.imageFourthRef, imageFifthRef: values.imageFifthRef, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestTwelveEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 13) {
                let tempObj = {question: values.question, imageFirstRef: values.imageFirstRef, imageSecondRef: values.imageSecondRef, imageThirdRef: values.imageThirdRef, imageFourthRef: values.imageFourthRef, imageFifthRef: values.imageFifthRef, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestThirteenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 14) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestFourteenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 15) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, imageFirstRefSubstance: values.imageFirstRefSubstance, imageSecondRefSubstance: values.imageSecondRefSubstance, imageThirdRefSubstance: values.imageThirdRefSubstance, imageFourthRefSubstance: values.imageFourthRefSubstance, nameOfSubstance: values.nameOfSubstance, imageFirstRefReagent: values.imageFirstRefReagent, imageSecondRefReagent: values.imageSecondRefReagent, imageThirdRefReagent: values.imageThirdRefReagent, imageFourthRefReagent: values.imageFourthRefReagent, imageFifthRefReagent: values.imageFifthRefReagent, imageSixRefReagent: values.imageSixRefReagent, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, reagent6: values.reagent6, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestFifteenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 16) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                setCurrentTestSixteenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 17) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, answer6: values.answer6, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                setCurrentTestSeventeenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 18) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                setCurrentTestEighteenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 19) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestNineteenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 20) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestTwentyEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 21) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                setCurrentTestTwentyOneEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 22) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestTwentyTwoEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 23) {
                let tempObj = {question: values.question, answer1: values.answer1, answer2: values.answer2, answer3: values.answer3, answer4: values.answer4, answer5: values.answer5, answer6: values.answer6, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, imageRef: values.imageRef}
                setCurrentTestTwentyThreeEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 24) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, substance4: values.substance4, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestTwentyFourEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 25) {
                let tempObj = {question: values.question, nameOfReagent: values.nameOfReagent, nameOfSubstance: values.nameOfSubstance, reagent1: values.reagent1, reagent2: values.reagent2, reagent3: values.reagent3, reagent4: values.reagent4, reagent5: values.reagent5, substance1: values.substance1, substance2: values.substance2, substance3: values.substance3, id: values.id, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber}
                setCurrentTestTwentyFiveEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 26) {
                let tempObj = {question: values.question, imageRef: values.imageRef, measuring: values.measuring, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestTwentySixEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 27) {
                let tempObj = {question: values.question, imageRefTask: values.imageRefTask, imageRef: values.imageRef, measuring: values.measuring, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestTwentySevenEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 28) {
                let tempObj = {question: values.question, imageRef: values.imageRef, measuring: values.measuring, rightAnswer: values.rightAnswer, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestTwentyEightEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 29) {
                let tempObj = {question: values.question, imageRef: values.imageRef, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestTwentyNineEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 30) {
                let tempObj = {question: values.question, imageRef: values.imageRef, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestThirtyEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 31) {
                let tempObj = {question: values.question, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestThirtyOneEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 32) {
                let tempObj = {question: values.question, imageRef: values.imageRef, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestThirtyTwoEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 33) {
                let tempObj = {question: values.question, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestThirtyThreeEx(tempObj);
            };
            if (values.testNumber === parseInt(e.target.id) && values.questionNumber === 34) {
                let tempObj = {question: values.question, testNumber: values.testNumber, questionNumber: values.questionNumber, id: values.id}
                setCurrentTestThirtyFourEx(tempObj);
            };
            // console.log(arrayOfObjects)
    })
    };

    async function deleteTestItem (goalID) {
        await deleteDoc(doc(database, "exercises", goalID));
       var button = document.getElementById(goalID);
       button.innerText = "Удалено";
       button.disabled = true;
       button.className = "bg-red-300 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed";
    };

    const showAlertBeforeDeleting = (e) => {
        let id = e.target.id;
        if (window.confirm("Вы уверены?\nЭтот пункт будет удален") === true) {
            deleteTestItem (id)
          } else {
            console.log("Действие отменено!");
          }
    };

    return (
        <div>
            <section className="bg-gray-100 py-1 text-center">
                <h3 className="mb-2 mt-0 text-2xl font-medium leading-tight text-primary">Выберите Вариант Теста</h3>
            </section>
            <section className="bg-gray-100 py-1 space-x-1 pb-3 px-2">
                {
                    numberOfEx.sort().map((number) => <button key={number} onClick={showTestByNumber} id={number} data-numberOfExButton={number} className="bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline" type="button">
                        Вариант {number}
                    </button>)
                }
            </section>
            <section className="bg-white py-4 flex flex-col items-center">
                { Object.keys(currentTestFirstEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestFirstEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestFirstEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestFirstEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFirstEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestFirstEx.answer1}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFirstEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestFirstEx.answer2}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFirstEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestFirstEx.answer3}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFirstEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestFirstEx.answer4}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFirstEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestFirstEx.answer5}</p>
                                </div>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestFirstEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestSecondEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestSecondEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestSecondEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestSecondEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestSecondEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestSecondEx.rightAnswer.includes("1") === true ? currentTestSecondEx.rightAnswer.indexOf("1")+1+") " : ""}{currentTestSecondEx.answer1}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestSecondEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestSecondEx.rightAnswer.includes("2") === true ? currentTestSecondEx.rightAnswer.indexOf("2")+1+") " : ""}{currentTestSecondEx.answer2}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestSecondEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestSecondEx.rightAnswer.includes("3") === true ? currentTestSecondEx.rightAnswer.indexOf("3")+1+") " : ""}{currentTestSecondEx.answer3}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestSecondEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestSecondEx.rightAnswer.includes("4") === true ? currentTestSecondEx.rightAnswer.indexOf("4")+1+") " : ""}{currentTestSecondEx.answer4}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestSecondEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestSecondEx.rightAnswer.includes("5") === true ? currentTestSecondEx.rightAnswer.indexOf("5")+1+") " : ""}{currentTestSecondEx.answer5}</p>
                                </div>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestSecondEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirdEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirdEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirdEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirdEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirdEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestThirdEx.answer1}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirdEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestThirdEx.answer2}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirdEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestThirdEx.answer3}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirdEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestThirdEx.answer4}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirdEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestThirdEx.answer5}</p>
                                </div>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirdEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestFourthEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestFourthEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestFourthEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestFourthEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFourthEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: containsNumber(currentTestFourthEx.answer1)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFourthEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: containsNumber(currentTestFourthEx.answer2)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFourthEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: containsNumber(currentTestFourthEx.answer3)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFourthEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: containsNumber(currentTestFourthEx.answer4)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFourthEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: containsNumber(currentTestFourthEx.answer5)}}></p>
                                </div>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestFourthEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestFifthEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestFifthEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestFifthEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestFifthEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(1) "+containsNumber(currentTestFifthEx.answer1)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(2) "+containsNumber(currentTestFifthEx.answer2)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(3) "+containsNumber(currentTestFifthEx.answer3)}}></p>
                                </div>
                            </div>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(4) "+containsNumber(currentTestFifthEx.answer4)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(5) "+containsNumber(currentTestFifthEx.answer5)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("6") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(6) "+containsNumber(currentTestFifthEx.answer6)}}></p>
                                </div>
                            </div>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("7") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(7) "+containsNumber(currentTestFifthEx.answer7)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("8") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(8) "+containsNumber(currentTestFifthEx.answer8)}}></p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestFifthEx.rightAnswer.includes("9") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "(9) "+containsNumber(currentTestFifthEx.answer9)}}></p>
                                </div>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifthEx.rightAnswer[0] ? currentTestFifthEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifthEx.rightAnswer[1] ? currentTestFifthEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifthEx.rightAnswer[2] ? currentTestFifthEx.rightAnswer[2] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestFifthEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestSixthEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestSixthEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestSixthEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestSixthEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestSixthEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSixthEx.answer1)}}></p>
                                    <p className={currentTestSixthEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSixthEx.answer2)}}></p>
                                    <p className={currentTestSixthEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSixthEx.answer3)}}></p>
                                    <p className={currentTestSixthEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSixthEx.answer4)}}></p>
                                    <p className={currentTestSixthEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSixthEx.answer5)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                X
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Y
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSixthEx.rightAnswer[0] ? currentTestSixthEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSixthEx.rightAnswer[1] ? currentTestSixthEx.rightAnswer[1] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestSixthEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestSeventhEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestSeventhEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestSeventhEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestSeventhEx.question}</h3>
                        <h3 className="text-center font-semibold">Вещества</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestSeventhEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestSeventhEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestSeventhEx.substance3)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestSeventhEx.substance4)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">Реагенты</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestSeventhEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSeventhEx.reagent1)}}></p>
                                    <p className={currentTestSeventhEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSeventhEx.reagent2)}}></p>
                                    <p className={currentTestSeventhEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSeventhEx.reagent3)}}></p>
                                    <p className={currentTestSeventhEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSeventhEx.reagent4)}}></p>
                                    <p className={currentTestSeventhEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSeventhEx.reagent5)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Г
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSeventhEx.rightAnswer[0] ? currentTestSeventhEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSeventhEx.rightAnswer[1] ? currentTestSeventhEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSeventhEx.rightAnswer[2] ? currentTestSeventhEx.rightAnswer[2] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSeventhEx.rightAnswer[3] ? currentTestSeventhEx.rightAnswer[3] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestSeventhEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestEightEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestEightEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestEightEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestEightEx.question}</h3>
                        <h3 className="text-center font-semibold">Исходные вещества</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestEightEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestEightEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestEightEx.substance3)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestEightEx.substance4)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">Продукты реакции</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestEightEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestEightEx.reagent1)}}></p>
                                    <p className={currentTestEightEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestEightEx.reagent2)}}></p>
                                    <p className={currentTestEightEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestEightEx.reagent3)}}></p>
                                    <p className={currentTestEightEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestEightEx.reagent4)}}></p>
                                    <p className={currentTestEightEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestEightEx.reagent5)}}></p>
                                    <p className={currentTestEightEx.rightAnswer.includes("6") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "6) "+containsNumber(currentTestEightEx.reagent6)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Г
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestEightEx.rightAnswer[0] ? currentTestEightEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestEightEx.rightAnswer[1] ? currentTestEightEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestEightEx.rightAnswer[2] ? currentTestEightEx.rightAnswer[2] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestEightEx.rightAnswer[3] ? currentTestEightEx.rightAnswer[3] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestEightEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestNinethEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestNinethEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestNinethEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestNinethEx.question}</h3>
                        <img src={currentTestNinethEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestNinethEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestNinethEx.answer1)}}></p>
                                    <p className={currentTestNinethEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestNinethEx.answer2)}}></p>
                                    <p className={currentTestNinethEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestNinethEx.answer3)}}></p>
                                    <p className={currentTestNinethEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestNinethEx.answer4)}}></p>
                                    <p className={currentTestNinethEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestNinethEx.answer5)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                X
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Y
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestNinethEx.rightAnswer[0] ? currentTestNinethEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestNinethEx.rightAnswer[1] ? currentTestNinethEx.rightAnswer[1] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestNinethEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTenthEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTenthEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTenthEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTenthEx.question}</h3>
                        <h3 className="text-center font-semibold">Формула вещества</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTenthEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTenthEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTenthEx.substance3)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">Класс / Группа</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestTenthEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTenthEx.reagent1)}}></p>
                                    <p className={currentTestTenthEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTenthEx.reagent2)}}></p>
                                    <p className={currentTestTenthEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTenthEx.reagent3)}}></p>
                                    <p className={currentTestTenthEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTenthEx.reagent4)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTenthEx.rightAnswer[0] ? currentTestTenthEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTenthEx.rightAnswer[1] ? currentTestTenthEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTenthEx.rightAnswer[2] ? currentTestTenthEx.rightAnswer[2] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTenthEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestElevenhEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestElevenhEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestElevenhEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestElevenhEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-row">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestElevenhEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestElevenhEx.answer1}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestElevenhEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestElevenhEx.answer2}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestElevenhEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestElevenhEx.answer3}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestElevenhEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestElevenhEx.answer4}</p>
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestElevenhEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{currentTestElevenhEx.answer5}</p>
                                </div>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestElevenhEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwelveEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwelveEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwelveEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwelveEx.question}</h3>
                        <img src={currentTestTwelveEx.questionImageRef} className="rounded-lg h-auto max-w-sm" />
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestTwelveEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>1)</p>{currentTestTwelveEx.imageFirstRef !== "" ? <img src={currentTestTwelveEx.imageFirstRef} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwelveEx.answer1)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestTwelveEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>2)</p>{currentTestTwelveEx.imageSecondRef !== "" ? <img src={currentTestTwelveEx.imageSecondRef} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwelveEx.answer2)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestTwelveEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>3)</p>{currentTestTwelveEx.imageThirdRef !== "" ? <img src={currentTestTwelveEx.imageThirdRef} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwelveEx.answer3)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestTwelveEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>4)</p>{currentTestTwelveEx.imageFourthRef !== "" ? <img src={currentTestTwelveEx.imageFourthRef} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwelveEx.answer4)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestTwelveEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>5)</p>{currentTestTwelveEx.imageFifthRef !== "" ? <img src={currentTestTwelveEx.imageFifthRef} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwelveEx.answer5)}}></p>}
                                </div>
                            </div>
                            <div className="text-left">
                                <p>Правильный ответ: {currentTestTwelveEx.rightAnswer.join(", ")}</p>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwelveEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirteenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirteenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirteenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirteenEx.question}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirteenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>1)</p>{currentTestThirteenEx.imageFirstRef !== "" ? <img src={currentTestThirteenEx.imageFirstRef} /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestThirteenEx.answer1)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirteenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>2)</p>{currentTestThirteenEx.imageSecondRef !== "" ? <img src={currentTestThirteenEx.imageSecondRef} /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestThirteenEx.answer2)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirteenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>3)</p>{currentTestThirteenEx.imageThirdRef !== "" ? <img src={currentTestThirteenEx.imageThirdRef} /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestThirteenEx.answer3)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirteenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>4)</p>{currentTestThirteenEx.imageFourthRef !== "" ? <img src={currentTestThirteenEx.imageFourthRef} /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestThirteenEx.answer4)}}></p>}
                                </div>
                                <div className="w-full border-b border-gray-200 rounded-t-lg ps-3">
                                    <p className={currentTestThirteenEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>5)</p>{currentTestThirteenEx.imageFifthRef !== "" ? <img src={currentTestThirteenEx.imageFifthRef} /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestThirteenEx.answer5)}}></p>}
                                </div>
                            </div>
                            <div className="text-left">
                                <p>Правильный ответ: {currentTestThirteenEx.rightAnswer.join(", ")}</p>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirteenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestFourteenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestFourteenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestFourteenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestFourteenEx.question}</h3>
                        <h3 className="text-center font-semibold">{currentTestFourteenEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">А)</p>{currentTestFourteenEx.imageFirstRefSubstance !== "" ? <img src={currentTestFourteenEx.imageFirstRefSubstance} class="h-auto w-72" /> : <p>{currentTestFourteenEx.substance1}</p>}
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">Б)</p>{currentTestFourteenEx.imageSecondRefSubstance !== "" ? <img src={currentTestFourteenEx.imageSecondRefSubstance} class="h-auto w-72" /> : <p>{currentTestFourteenEx.substance2}</p>}
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">В)</p>{currentTestFourteenEx.imageThirdRefSubstance !== "" ? <img src={currentTestFourteenEx.imageThirdRefSubstance} class="h-auto w-72" /> : <p>{currentTestFourteenEx.substance3}</p>}
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">Г)</p>{currentTestFourteenEx.imageFourthRefSubstance !== "" ? <img src={currentTestFourteenEx.imageFourthRefSubstance} class="h-auto w-72" /> : <p>{currentTestFourteenEx.substance4}</p>}
                            </div>
                        <h3 className="text-center font-semibold">{currentTestFourteenEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                <p className={currentTestFourteenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>1)</p>{currentTestFourteenEx.imageFirstRefReagent !== "" ? <img src={currentTestFourteenEx.imageFirstRefReagent} class="h-auto w-72" /> : <p>{currentTestFourteenEx.reagent1}</p>}
                                <p className={currentTestFourteenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>2)</p>{currentTestFourteenEx.imageSecondRefReagent !== "" ? <img src={currentTestFourteenEx.imageSecondRefReagent} class="h-auto w-72" /> : <p>{currentTestFourteenEx.reagent2}</p>}
                                <p className={currentTestFourteenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>3)</p>{currentTestFourteenEx.imageThirdRefReagent !== "" ? <img src={currentTestFourteenEx.imageThirdRefReagent} class="h-auto w-72" /> : <p>{currentTestFourteenEx.reagent3}</p>}
                                <p className={currentTestFourteenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>4)</p>{currentTestFourteenEx.imageFourthRefReagent !== "" ? <img src={currentTestFourteenEx.imageFourthRefReagent} class="h-auto w-72" /> : <p>{currentTestFourteenEx.reagent4}</p>}
                                <p className={currentTestFourteenEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>5)</p>{currentTestFourteenEx.imageFifthRefReagent !== "" ? <img src={currentTestFourteenEx.imageFifthRefReagent} class="h-auto w-72" /> : <p>{currentTestFourteenEx.reagent5}</p>}
                                <p className={currentTestFourteenEx.rightAnswer.includes("6") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>6)</p>{currentTestFourteenEx.imageSixRefReagent !== "" ? <img src={currentTestFourteenEx.imageSixRefReagent} class="h-auto w-72" /> : <p>{currentTestFourteenEx.reagent6}</p>}
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Г
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFourteenEx.rightAnswer[0] ? currentTestFourteenEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFourteenEx.rightAnswer[1] ? currentTestFourteenEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFourteenEx.rightAnswer[2] ? currentTestFourteenEx.rightAnswer[2] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFourteenEx.rightAnswer[3] ? currentTestFourteenEx.rightAnswer[3] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestFourteenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestFifteenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestFifteenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestFifteenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestFifteenEx.question}</h3>
                        <h3 className="text-center font-semibold">{currentTestFifteenEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">А)</p>{currentTestFifteenEx.imageFirstRefSubstance !== "" ? <img src={currentTestFifteenEx.imageFirstRefSubstance} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifteenEx.substance1)}}></p>}
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">Б)</p>{currentTestFifteenEx.imageSecondRefSubstance !== "" ? <img src={currentTestFifteenEx.imageSecondRefSubstance} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifteenEx.substance2)}}></p>}
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">В)</p>{currentTestFifteenEx.imageThirdRefSubstance !== "" ? <img src={currentTestFifteenEx.imageThirdRefSubstance} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifteenEx.substance3)}}></p>}
                                <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">Г)</p>{currentTestFifteenEx.imageFourthRefSubstance !== "" ? <img src={currentTestFifteenEx.imageFourthRefSubstance} class="h-auto w-72" /> : <p dangerouslySetInnerHTML={{__html: containsNumber(currentTestFifteenEx.substance4)}}></p>}
                            </div>
                        <h3 className="text-center font-semibold">{currentTestFifteenEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                <p className={currentTestFifteenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>1)</p>{currentTestFifteenEx.imageFirstRefReagent !== "" ? <img src={currentTestFifteenEx.imageFirstRefReagent} class="h-auto w-72" /> : <p>{currentTestFifteenEx.reagent1}</p>}
                                <p className={currentTestFifteenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>2)</p>{currentTestFifteenEx.imageSecondRefReagent !== "" ? <img src={currentTestFifteenEx.imageSecondRefReagent} class="h-auto w-72" /> : <p>{currentTestFifteenEx.reagent2}</p>}
                                <p className={currentTestFifteenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>3)</p>{currentTestFifteenEx.imageThirdRefReagent !== "" ? <img src={currentTestFifteenEx.imageThirdRefReagent} class="h-auto w-72" /> : <p>{currentTestFifteenEx.reagent3}</p>}
                                <p className={currentTestFifteenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>4)</p>{currentTestFifteenEx.imageFourthRefReagent !== "" ? <img src={currentTestFifteenEx.imageFourthRefReagent} class="h-auto w-72" /> : <p>{currentTestFifteenEx.reagent4}</p>}
                                <p className={currentTestFifteenEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>5)</p>{currentTestFifteenEx.imageFifthRefReagent !== "" ? <img src={currentTestFifteenEx.imageFifthRefReagent} class="h-auto w-72" /> : <p>{currentTestFifteenEx.reagent5}</p>}
                                {/* ниже опционально */}
                                <p className={currentTestFifteenEx.rightAnswer.includes("6") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>6)</p>{currentTestFifteenEx.imageSixRefReagent !== "" ? <img src={currentTestFifteenEx.imageSixRefReagent} class="h-auto w-72" /> : <p>{currentTestFifteenEx.reagent6}</p>}
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Г
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifteenEx.rightAnswer[0] ? currentTestFifteenEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifteenEx.rightAnswer[1] ? currentTestFifteenEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifteenEx.rightAnswer[2] ? currentTestFifteenEx.rightAnswer[2] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestFifteenEx.rightAnswer[3] ? currentTestFifteenEx.rightAnswer[3] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestFifteenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestSixteenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestSixteenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestSixteenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestSixteenEx.question}</h3>
                        <img src={currentTestSixteenEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestSixteenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSixteenEx.answer1)}}></p>
                                    <p className={currentTestSixteenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSixteenEx.answer2)}}></p>
                                    <p className={currentTestSixteenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSixteenEx.answer3)}}></p>
                                    <p className={currentTestSixteenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSixteenEx.answer4)}}></p>
                                    <p className={currentTestSixteenEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSixteenEx.answer5)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                X
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Y
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSixteenEx.rightAnswer[0] ? currentTestSixteenEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestSixteenEx.rightAnswer[1] ? currentTestSixteenEx.rightAnswer[1] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestSixteenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestSeventeenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestSeventeenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestSeventeenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestSeventeenEx.question}</h3>
                        <img src={currentTestSeventeenEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestSeventeenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestSeventeenEx.answer1)}}></p>
                                    <p className={currentTestSeventeenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestSeventeenEx.answer2)}}></p>
                                    <p className={currentTestSeventeenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestSeventeenEx.answer3)}}></p>
                                    <p className={currentTestSeventeenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestSeventeenEx.answer4)}}></p>
                                    <p className={currentTestSeventeenEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestSeventeenEx.answer5)}}></p>
                                    <p className={currentTestSeventeenEx.rightAnswer.includes("6") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "6) "+containsNumber(currentTestSeventeenEx.answer6)}}></p>
                            </div>
                            <div className="text-left">
                                Правильный ответ: {currentTestSeventeenEx.rightAnswer.join(", ")}
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestSeventeenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestEighteenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestEighteenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestEighteenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestEighteenEx.question}</h3>
                        {currentTestEighteenEx.imageRef !== "" ? <img src={currentTestEighteenEx.imageRef} className="rounded-lg h-auto max-w-sm" /> : ""}
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestEighteenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>1) {currentTestEighteenEx.answer1}</p>
                                    <p className={currentTestEighteenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>2) {currentTestEighteenEx.answer2}</p>
                                    <p className={currentTestEighteenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>3) {currentTestEighteenEx.answer3}</p>
                                    <p className={currentTestEighteenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>4) {currentTestEighteenEx.answer4}</p>
                                    <p className={currentTestEighteenEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>5) {currentTestEighteenEx.answer5}</p>
                            </div>
                            <div className="text-left">
                                Правильный ответ: {currentTestEighteenEx.rightAnswer.join(", ")}
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestEighteenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestNineteenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestNineteenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestNineteenEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestNineteenEx.question}</h3>
                        <h3 className="text-center font-semibold">{currentTestNineteenEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestNineteenEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestNineteenEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestNineteenEx.substance3)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">{currentTestNineteenEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestNineteenEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>1) {currentTestNineteenEx.reagent1}</p>
                                    <p className={currentTestNineteenEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>2) {currentTestNineteenEx.reagent2}</p>
                                    <p className={currentTestNineteenEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>3) {currentTestNineteenEx.reagent3}</p>
                                    <p className={currentTestNineteenEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>4) {currentTestNineteenEx.reagent4}</p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestNineteenEx.rightAnswer[0] ? currentTestNineteenEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestNineteenEx.rightAnswer[1] ? currentTestNineteenEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestNineteenEx.rightAnswer[2] ? currentTestNineteenEx.rightAnswer[2] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestNineteenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyEx.question}</h3>
                        <h3 className="text-center font-semibold">{currentTestTwentyEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTwentyEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTwentyEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTwentyEx.substance3)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">{currentTestTwentyEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestTwentyEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwentyEx.reagent1)}}></p>
                                    <p className={currentTestTwentyEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwentyEx.reagent2)}}></p>
                                    <p className={currentTestTwentyEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwentyEx.reagent3)}}></p>
                                    <p className={currentTestTwentyEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTwentyEx.reagent4)}}></p>
                                    <p className={currentTestTwentyEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestTwentyEx.reagent5)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyEx.rightAnswer[0] ? currentTestTwentyEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyEx.rightAnswer[1] ? currentTestTwentyEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyEx.rightAnswer[2] ? currentTestTwentyEx.rightAnswer[2] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyOneEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyOneEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyOneEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyOneEx.question}</h3>
                        <img src={currentTestTwentyOneEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black">{"1) "+currentTestTwentyOneEx.answer1}</p>
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black">{"2) "+currentTestTwentyOneEx.answer2}</p>
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black">{"3) "+currentTestTwentyOneEx.answer3}</p>
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black">{"4) "+currentTestTwentyOneEx.answer4}</p>
                            </div>
                            <div className="flex flex-row items-center space-x-1">
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {currentTestTwentyOneEx.rightAnswer[0] ? currentTestTwentyOneEx.rightAnswer[0] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>→</p>
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {currentTestTwentyOneEx.rightAnswer[1] ? currentTestTwentyOneEx.rightAnswer[1] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>→</p>
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {currentTestTwentyOneEx.rightAnswer[2] ? currentTestTwentyOneEx.rightAnswer[2] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>→</p>
                        <table style={{border: "1px solid black"}}>
                            <tbody>
                                <tr>
                                    <td style={{border: "1px solid black", padding: "10px"}}>
                                        {currentTestTwentyOneEx.rightAnswer[3] ? currentTestTwentyOneEx.rightAnswer[3] : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyOneEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyTwoEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyTwoEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyTwoEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left" dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwentyTwoEx.question)}}></h3>
                        <h3 className="text-center font-semibold">{currentTestTwentyTwoEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTwentyTwoEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTwentyTwoEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTwentyTwoEx.substance3)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestTwentyTwoEx.substance4)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">{currentTestTwentyTwoEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black" dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwentyTwoEx.reagent1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black" dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwentyTwoEx.reagent2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black" dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwentyTwoEx.reagent3)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Г
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyTwoEx.rightAnswer[0] ? currentTestTwentyTwoEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyTwoEx.rightAnswer[1] ? currentTestTwentyTwoEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyTwoEx.rightAnswer[2] ? currentTestTwentyTwoEx.rightAnswer[2] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyTwoEx.rightAnswer[3] ? currentTestTwentyTwoEx.rightAnswer[3] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyTwoEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyThreeEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyThreeEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyThreeEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyThreeEx.question}</h3>
                        <img src={currentTestTwentyThreeEx.imageRef} className="rounded-lg h-auto max-w-sm" />
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestTwentyThreeEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{"1) "+currentTestTwentyThreeEx.answer1}</p>
                                    <p className={currentTestTwentyThreeEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{"2) "+currentTestTwentyThreeEx.answer2}</p>
                                    <p className={currentTestTwentyThreeEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{"3) "+currentTestTwentyThreeEx.answer3}</p>
                                    <p className={currentTestTwentyThreeEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{"4) "+currentTestTwentyThreeEx.answer4}</p>
                                    <p className={currentTestTwentyThreeEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{"5) "+currentTestTwentyThreeEx.answer5}</p>
                                    <p className={currentTestTwentyThreeEx.rightAnswer.includes("6") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"}>{"6) "+currentTestTwentyThreeEx.answer6}</p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                X
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Y
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyThreeEx.rightAnswer[0] ? currentTestTwentyThreeEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyThreeEx.rightAnswer[1] ? currentTestTwentyThreeEx.rightAnswer[1] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyThreeEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyFourEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyFourEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyFourEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyFourEx.question}</h3>
                        <h3 className="text-center font-semibold">{currentTestTwentyFourEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "А) "+containsNumber(currentTestTwentyFourEx.substance1)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Б) "+containsNumber(currentTestTwentyFourEx.substance2)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "В) "+containsNumber(currentTestTwentyFourEx.substance3)}}></p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700" dangerouslySetInnerHTML={{__html: "Г) "+containsNumber(currentTestTwentyFourEx.substance4)}}></p>
                            </div>
                        <h3 className="text-center font-semibold">{currentTestTwentyFourEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className={currentTestTwentyFourEx.rightAnswer.includes("1") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "1) "+containsNumber(currentTestTwentyFourEx.reagent1)}}></p>
                                    <p className={currentTestTwentyFourEx.rightAnswer.includes("2") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "2) "+containsNumber(currentTestTwentyFourEx.reagent2)}}></p>
                                    <p className={currentTestTwentyFourEx.rightAnswer.includes("3") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "3) "+containsNumber(currentTestTwentyFourEx.reagent3)}}></p>
                                    <p className={currentTestTwentyFourEx.rightAnswer.includes("4") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "4) "+containsNumber(currentTestTwentyFourEx.reagent4)}}></p>
                                    <p className={currentTestTwentyFourEx.rightAnswer.includes("5") === true ? "w-full py-1 ms-2 text-xl font-bold text-green-600" : "w-full py-1 ms-2 text-xl font-medium text-red-500"} dangerouslySetInnerHTML={{__html: "5) "+containsNumber(currentTestTwentyFourEx.reagent5)}}></p>
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Г
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFourEx.rightAnswer[0] ? currentTestTwentyFourEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFourEx.rightAnswer[1] ? currentTestTwentyFourEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFourEx.rightAnswer[2] ? currentTestTwentyFourEx.rightAnswer[2] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFourEx.rightAnswer[3] ? currentTestTwentyFourEx.rightAnswer[3] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyFourEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyFiveEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyFiveEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyFiveEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyFiveEx.question}</h3>
                        <h3 className="text-center font-semibold">{currentTestTwentyFiveEx.nameOfSubstance}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">А) {currentTestTwentyFiveEx.substance1}</p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">Б) {currentTestTwentyFiveEx.substance2}</p>
                                    <p className="w-full py-1 ms-2 text-xl font-bold text-gray-700">В) {currentTestTwentyFiveEx.substance3}</p>
                            </div>
                        <h3 className="text-center font-semibold">{currentTestTwentyFiveEx.nameOfReagent}</h3>
                            <div className="text-sm font-medium text-gray-900 border border-gray-200 rounded-lg mb-4 flex flex-col text-left">
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black">1) {currentTestTwentyFiveEx.reagent1}</p>
                                    <p className="w-full py-1 ms-2 text-xl font-medium text-black">2) {currentTestTwentyFiveEx.reagent2}</p>
                                    {currentTestTwentyFiveEx.reagent3 === "" ? "" : <p className="w-full py-1 ms-2 text-xl font-medium text-black">3) {currentTestTwentyFiveEx.reagent3}</p>}
                                    {currentTestTwentyFiveEx.reagent4 === "" ? "" : <p className="w-full py-1 ms-2 text-xl font-medium text-black">4) {currentTestTwentyFiveEx.reagent4}</p>}
                                    {currentTestTwentyFiveEx.reagent5 === "" ? "" : <p className="w-full py-1 ms-2 text-xl font-medium text-black">5) {currentTestTwentyFiveEx.reagent5}</p>}
                            </div>
                            <div>
                                <table style={{border: "1px solid black"}}>
                                    <thead>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                А
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                Б
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                В
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFiveEx.rightAnswer[0] ? currentTestTwentyFiveEx.rightAnswer[0] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFiveEx.rightAnswer[1] ? currentTestTwentyFiveEx.rightAnswer[1] : ""}
                                            </td>
                                            <td style={{border: "1px solid black", padding: "8px"}}>
                                                {currentTestTwentyFiveEx.rightAnswer[2] ? currentTestTwentyFiveEx.rightAnswer[2] : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyFiveEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentySixEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentySixEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentySixEx.id}</h5>
                        <img src={currentTestTwentySixEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentySixEx.question}</h3>
                        <div className="text-left">
                            <p className="w-1/4 py-1 text-xl font-medium text-black">Ответ: {currentTestTwentySixEx.rightAnswer} ({currentTestTwentySixEx.measuring})</p>
                        </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentySixEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentySevenEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentySevenEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentySevenEx.id}</h5>
                        <img src={currentTestTwentySevenEx.imageRefTask} className="rounded-lg h-auto max-w-sm" />
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left" dangerouslySetInnerHTML={{__html: containsNumber(currentTestTwentySevenEx.question)}}></h3>
                        <img src={currentTestTwentySevenEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        <div className="text-left">
                            <p className="w-1/4 py-1 text-xl font-medium text-black">Ответ: {currentTestTwentySevenEx.rightAnswer} ({currentTestTwentySevenEx.measuring})</p>
                        </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentySevenEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyEightEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyEightEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyEightEx.id}</h5>
                        <img src={currentTestTwentyEightEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyEightEx.question}</h3>
                        <div className="text-left">
                            <p className="w-1/4 py-1 text-xl font-medium text-black">Ответ: {currentTestTwentyEightEx.rightAnswer} ({currentTestTwentyEightEx.measuring})</p>
                        </div>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyEightEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestTwentyNineEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestTwentyNineEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestTwentyNineEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestTwentyNineEx.question}</h3>
                        <img src={currentTestTwentyNineEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestTwentyNineEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirtyEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirtyEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirtyEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirtyEx.question}</h3>
                        <img src={currentTestThirtyEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirtyEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirtyOneEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirtyOneEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirtyOneEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirtyOneEx.question}</h3>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirtyOneEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirtyTwoEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirtyTwoEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirtyTwoEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirtyTwoEx.question}</h3>
                        <img src={currentTestThirtyTwoEx.imageRef} className="rounded-lg h-auto max-w-lg" />
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirtyTwoEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirtyThreeEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirtyThreeEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirtyThreeEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirtyThreeEx.question}</h3>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirtyThreeEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
                { Object.keys(currentTestThirtyFourEx).length !== 0 ? 
                    <section className="bg-gray-100 py-1 w-full md:w-11/12 lg:w-11/12 rounded-xl my-2 text-center px-4">
                        <h2 className='mb-0 text-xl text-black text-left'>Вопрос № {currentTestThirtyFourEx.questionNumber}</h2>
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{currentTestThirtyFourEx.id}</h5>
                        <h3 className="mb-0 font-semibold text-gray-900 mb-3 text-left">{currentTestThirtyFourEx.question}</h3>
                        <div className='text-right mb-2'>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={currentTestThirtyFourEx.id}>Удалить</button>
                        </div>
                    </section>
                     : ""
                }
            </section>
        </div>
    )
}

function StudentResults() {
    const [users, setUsers] = useState();
    const [currentStudentResult, setCurrentStudentResult] = useState();

    useEffect(()=>{
        const pathToUsers = collection(database, "users")
        const getUsers = async() => {
            const dbDataUser = await getDocs(pathToUsers);
            let receivedDataUser = dbDataUser.docs.map(doc=>({...doc.data(), id:doc.id}));
            setUsers(receivedDataUser);
        }
        getUsers();
        
        // console.log("data has been changed")
    },[])

    const [arrayOfExs, setArrayOfExs] = useState([]);
    const [currentUser, setCurrentUser] = useState("");

    const chooseStudenthandle = async (e) => {
        setCurrentStudentResult();
        const userAnswersRef = collection(database, "userAnswers");
        const q = query(userAnswersRef, where("userId", "==", e.target.value));
        const querySnapshot = await getDocs(q);
        let tempArray = [];
        querySnapshot.forEach((doc) => {
        tempArray.push(doc.data());
        });
        setCurrentStudentResult(tempArray);
        const currentUserTemp = users.find(user => user.id === e.target.value);
        setCurrentUser(currentUserTemp);

        const pathToTheExs = collection(database, "exercises");
        const dbData = await getDocs(pathToTheExs)
        let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
        setArrayOfExs(receivedData);
    };

    return (
        <div className="flex flex-col justify-center px-3 py-2 bg-gray-300">
            <select onChange={chooseStudenthandle} id="selection" className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden disabled selected value='0'>Выберите студента</option>
                {users ? (
                    users.map(user => 
                        {return <option key={user.id} value={user.id}>
                            {user.localName ? user.localName : user.name}
                        </option>})
                ) : ""}
            </select>
                {currentStudentResult ? 
                currentStudentResult.map(item => {
                    return <section key={item.date} className="flex flex-col justify-center px-3 py-2 space-y-4 bg-white rounded-lg mb-1 mt-2">
                        <div>Тест №{item.testNumber.toString().slice(0, -1)}</div>
                        <div>Дата прохождения: {item.date.toDate().getDate()+"."+(item.date.toDate().getMonth()+1)+"."+item.date.toDate().getFullYear()} в {item.date.toDate().toLocaleTimeString('ru-RU')}</div>
                        <h3>1 часть:</h3>
                        <PDFDownloadLink document ={<PDF 
                        date={item.date}
                        user={currentUser}
                        testNumber={item.testNumber}
                        tasks={arrayOfExs}
                        score={item.score}
                        answer1={item.question1}
                        answer2={item.question2.join(", ")}
                        answer3={item.question3}
                        answer4={item.question4}
                        answer5={item.question5.join(", ")}
                        answer6={item.question6.join(", ")}
                        answer7={item.question7.join(", ")}
                        answer8={item.question8.join(", ")}
                        answer9={item.question9.join(", ")}
                        answer10={item.question10.join(", ")}
                        answer11={item.question11}
                        answer12={item.question12}
                        answer13={item.question13}
                        answer14={item.question14}
                        answer15={item.question15}
                        answer16={item.question16}
                        answer17={item.question17}
                        answer18={item.question18}
                        answer19={item.question19.join(", ")}
                        answer20={item.question20.join(", ")}
                        answer21={item.question21.join(", ")}
                        answer22={item.question22.join(", ")}
                        answer23={item.question23.join(", ")}
                        answer24={item.question24.join(", ")}
                        answer25={item.question25.join(", ")}
                        answer26={item.question26}
                        answer27={item.question27}
                        answer28={item.question28}
                         />} fileName={currentUser.localName ? currentUser.localName+"_1-28.pdf" : currentUser.name+"_1-28.pdf"}>
                            {({loading, url, error, blob}) => loading ? <button>
                                Загрузка ...
                            </button> : <button className="w-64 bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 border border-green-800 rounded-full inline-flex items-center justify-center">
                            <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                            <span>Скачать 1 часть (pdf)</span>
                        </button>}
                        </PDFDownloadLink>
                        <h3>2 часть:</h3>
                        <div>29 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question29}>Посмотреть ответ</a></div>
                        <div>30 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question30}>Посмотреть ответ</a></div>
                        <div>31 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question31}>Посмотреть ответ</a></div>
                        <div>32 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question32}>Посмотреть ответ</a></div>
                        <div>33 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question33}>Посмотреть ответ</a></div>
                        <div>34 Задание: <a className="cursor-pointer bg-blue-500 hover:bg-cyan-400 text-white font-bold py-2 px-4 border border-blue-700 rounded" target="_blank" href={item.question34}>Посмотреть ответ</a></div>
                    </section> 
                })
                : ""}
        </div>
    )
}

function ImageBase() {
    const [picName, setPicName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [disableButtonImage, setDisableButtonImage] = useState(false);
    const [imageRef, setImageRef] = useState("");
    const [imageUpload, setImageUpload] = useState(null);
    const [messageImageUpload, setMessageImageUpload] = useState("Отправить картинку на сервер");
    const [data, setData] = useState([]);
    const [toggle, setToggle] = useState(false);

    const pathToImageBase = collection(database, "images");

    function toggleReload() {
        if(toggle === false) {
            setToggle(true)
        } else {
            setToggle(false)
        }
    };

    useEffect(()=>{
        var array = [];
        const getData = async() => {
            const dbData = await getDocs(pathToImageBase)
            let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
            receivedData.map(values => (
                array.push(values.testNumber)
            ));
            const uniqueArray = [...new Set(array)];
            setData(receivedData);
        }
        getData();
    },[toggle])

    const hadleCreate = async (e) => {
        let message = "Необходимо заполнить ВСЕ поля"
        e.preventDefault();
        if(picName === ""){
            alert(message)
        } else if(imageRef === ""){
            alert(message)
        } else {
            setIsLoading(true);
            const { id: documentId } = await addDoc(pathToImageBase, {
                imageRef: imageRef,
                picName: picName
            });
            setPicName("");
            setDisableButtonImage(false);
            setImageUpload(null);
            await updateDoc(doc(pathToImageBase, documentId), {
                id: documentId
            });
            setImageRef("");
            setIsLoading(false);
            toggleReload();
        };
    };

    const uploadImage = (e) => {
        e.preventDefault();
        if(imageUpload == null) return;
        const imageRef = ref(storage, `imageBase/${"image_" + v4()}`)
        uploadBytes(imageRef, imageUpload).then(() => {
            setMessageImageUpload("Картинка загружена на сервер");
            getDownloadURL(imageRef).then((url) => {
                setImageRef(url);
            });
            setDisableButtonImage(true);
        });
    };

    async function deleteTestItem (goalID) {
        await deleteDoc(doc(database, "images", goalID));
       var button = document.getElementById(goalID);
       button.innerText = "Удалено";
       button.disabled = true;
       button.className = "bg-red-300 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed";
    };

    const showAlertBeforeDeleting = (e) => {
        let id = e.target.id;
        console.log(e.target.dataset.image)
        if (window.confirm("Вы уверены?\nЭтот пункт будет удален") === true) {
            deleteTestItem (id);
            const desertRef = ref(storage, e.target.dataset.image);
            deleteObject(desertRef).then(() => {

              }).catch((error) => {
                console.log(error);
              });
          } else {
            console.log("Действие отменено!");
          }
    };

    return (
        <div className="space-y-2 bg-gray-100">
            <section className="bg-white bg-center bg-fixed p-4">
            <h2 className="text-center font-semibold">Добавьте изображение в базу</h2>
                <form className="w-full max-w-3xl container mx-auto">
                    <div className="flex flex-col mx-0 mb-2 space-y-1">
                        <div className="w-full px-1 mb-6 md:mb-0 flex items-center">
                            <input value={picName} onChange={(e) => setPicName(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="picName" type="text" placeholder="Название вещества"></input>
                        </div>
                        <div className="flex flex-row mx-1 mb-2 items-center justify-between space-x-1">
                        <input className="block w-full text-lg text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-1 p-1" type="file" onChange={(event) => {setImageUpload(event.target.files[0])}} />
                        <button disabled={disableButtonImage} onClick={uploadImage} className="bg-gray-300 hover:bg-yellow-400 text-black font-semibold py-2 px-2 rounded focus:outline-none focus:shadow-outline">{messageImageUpload}</button>
                        {imageRef !== "" ?
                        <img src={imageRef} className="rounded-lg h-12 w-auto" />
                        : ""}
                    </div>
                    </div>
                    <div className="flex mx-0 mb-2 py-2 space-x-1 justify-between">
                        <button onClick={hadleCreate} className="bg-blue-300 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">{isLoading ? "Ждите..." : "Отправить на сервер"}</button>
                    </div>
                </form>
            </section>
            <section className="bg-white bg-center bg-fixed p-4 flex flex-col justify-center px-12">
            <h2 className="text-center font-semibold">Изображения на сервере</h2>
                {data.map(item => {
                    return(<div key={item.id} className="flex flex-row justify-between items-center space-x-2 text-3xl mb-2 border border-gray-200 rounded-lg p-2">
                        <h1 className="pl-10">{item.picName}</h1>
                        <img className="w-auto h-24 rounded-lg" src={item.imageRef} />
                        <h5 className='mb-0 text-sm text-gray-400 text-right'>{item.id}</h5>
                        <div className='text-right pr-10'>
                            <button  data-image={item.imageRef} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={showAlertBeforeDeleting} id={item.id}>Удалить</button>
                        </div>
                    </div>)
                })}
            </section>
        </div>
    )
}

function StudentSubscription() {
    const [users, setUsers] = useState();
    const [currentUser, setCurrentUser] = useState("");
    const [currentUserSubsInfo, setCurrentUserSubsInfo] = useState("");

    useEffect(()=>{
        const pathToUsers = collection(database, "users")
        const getUsers = async() => {
            const dbDataUser = await getDocs(pathToUsers);
            let receivedDataUser = dbDataUser.docs.map(doc=>({...doc.data(), id:doc.id}));
            setUsers(receivedDataUser);
        }
        getUsers();
        
        // console.log("data has been changed")
    },[]);

    const chooseStudenthandle = async (e) => {
        setCurrentUserSubsInfo();
        const userSubscriptionRef = collection(database, "userSubscriptions");
        const q = query(userSubscriptionRef, where("userId", "==", e.target.value));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setCurrentUserSubsInfo(doc.data());
        });

        const currentUserTemp = users.find(user => user.id === e.target.value);
        setCurrentUser(currentUserTemp);
    };

    const [isLoading, setIsLoading] = useState();

    const addSubscriptionHandle = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // const userSubscriptionRef = collection(database, "userSubscriptions");
        let date = new Date();
        await setDoc(doc(database, "userSubscriptions", currentUser.id), {
            userId: currentUser.id,
            date: date,
            subscriptionUntill: startDate
          });
        alert("Подписка обновлена. Информация будет изменена после следующей загрузки данных пользователя")
        setIsLoading(false);
    };

    const [startDate, setStartDate] = useState(new Date());

    return(
        <div className="flex flex-col justify-center px-3 py-2 bg-gray-300">
            <select onChange={chooseStudenthandle} id="selection" className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option hidden disabled selected value='0'>Выберите студента для просмотра его подписки</option>
                {users ? (
                    users.map(user => 
                        {return <option key={user.id} value={user.id}>
                            {user.localName ? user.localName : user.name}
                        </option>})
                ) : ""}
            </select>
            {currentUserSubsInfo ? 
                    <section className="flex flex-col justify-center px-3 py-2 space-y-4 bg-white rounded-lg mb-1 mt-2">
                        <div>Статус: {currentUserSubsInfo.subscriptionUntill.toDate() > new Date() ? <button class="bg-green-500 text-white font-bold py-1 px-2 rounded">Активна</button> : <button class="bg-red-500 text-white font-bold py-1 px-2 rounded">Неактивна</button>}</div>
                        <div>Последняя подписка изменена: {currentUserSubsInfo.date.toDate().getDate()+"."+(currentUserSubsInfo.date.toDate().getMonth()+1)+"."+currentUserSubsInfo.date.toDate().getFullYear()} в {currentUserSubsInfo.date.toDate().toLocaleTimeString('ru-RU')}</div>
                        <div>Текущая подписка до: {currentUserSubsInfo.subscriptionUntill ? currentUserSubsInfo.subscriptionUntill.toDate().getDate()+"."+(currentUserSubsInfo.subscriptionUntill.toDate().getMonth()+1)+"."+currentUserSubsInfo.subscriptionUntill.toDate().getFullYear()+" в "+currentUserSubsInfo.subscriptionUntill.toDate().toLocaleTimeString('ru-RU') : "нет"}</div>
                        <div>Открыть доступ к материалам до: <DatePicker showIcon selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" /><br></br>
                            <button onClick={addSubscriptionHandle} className="ml-2 w-32 bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 border border-green-800 rounded-full inline-flex items-center justify-center">
                                <span>{isLoading ? "Ждите..." : "Установить"}</span>
                            </button>
                        </div>
                    </section> 
                :   <section className="flex flex-col justify-center px-3 py-2 space-y-4 bg-white rounded-lg mb-1 mt-2">
                        <div>Статус: <button class="bg-red-500 text-white font-bold py-1 px-2 rounded">Неактивна</button></div>
                        <div>Последняя подписка изменена: никогда</div>
                        <div>Текущая подписка до: нет</div>
                        <div>Открыть доступ к материалам до: <DatePicker showIcon selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" /><br></br>
                            {currentUser.id ? <button onClick={addSubscriptionHandle} className="ml-2 w-32 bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 border border-green-800 rounded-full inline-flex items-center justify-center">
                                <span>{isLoading ? "Ждите..." : "Установить"}</span>
                            </button> :
                            <button className="ml-2 w-56 bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 border border-green-800 rounded-full inline-flex items-center justify-center opacity-50 cursor-not-allowed">
                                <span>Выберите студента</span>
                            </button>}
                        </div>
                    </section>}
        </div>
    )
}
