import React from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import diploma_01 from './diploma_01.jpg';
import diploma_02 from './diploma_02.jpg';
import diploma_03 from './diploma_03.jpg';
import diploma_04 from './diploma_04.jpg';
import diploma_05 from './diploma_05.jpg';
import diploma_06 from './diploma_06.jpg';
import diploma_07 from './diploma_07.jpg';

const About = () => {
    const images = [diploma_01, diploma_02, diploma_03, diploma_04, diploma_05, diploma_06, diploma_07]

    return (
        <div className="px-4 bg-blue-100 dark:bg-slate-700 h-full space-y-4">
            <h1 className="text-center text-3xl dark:text-white">Репетитор по химии Румянцев Алексей Александрович</h1>
            <div className="space-y-2 flex flex-col lg:flex-row lg:space-x-2 lg:space-y-0">
                <div className="bg-white dark:bg-slate-500 shadow-xl dark:shadow-grey-900 p-6 rounded-xl lg:w-1/2">
                    <h2 className="font-bold dark:text-gray-300">ОБРАЗОВАНИЕ И ОПЫТ</h2>
                    <p>Закончил Московский Государственный Университет тонкой химической технологии им. М. В. Ломоносова. Преподаю в медицинских классах. Читаю лекции и веду семинарские занятия по физической и коллоидной химии. Имею статус сертифицированного эксперта ЕГЭ с правом проверки экзамена. Ежегодная проверка экзамена позволяет мне видеть типовые ошибки учащихся и корректировать свой курс с учетом этой информации. Веду активную преподавательскую и научную деятельность (являюсь научным сотрудником Института общей и неорганической химии им. Н.С.Курнакова). Область научных интересов наноматериалы и материалы электронной техники.</p>
                </div>
                <div className="bg-white dark:bg-slate-500 shadow-xl dark:shadow-grey-900 p-6 rounded-xl lg:w-1/2">
                    <ul>
                        <li>
                            Сертифицированный эксперт ЕГЭ по химии с правом проверки экзамена
                        </li>
                        <li>
                            Провожу 1300 уроков ежегодно
                        </li>
                        <li>
                            12 летний опыт преподавания в медицинских и естественно-научных классах
                        </li>
                        <li>
                            Количество учеников, поступивших на бюджет в 2024: 14 человек
                        </li>
                    </ul>
                    <div>Основные специализации моей работы:</div>
                    <ul className="list-disc pl-6">
                        <li>
                            Подготовка к поступлению в топовые школы г. Москвы («Летово»; Московский химический лицей; ЦПМ; Курчатовская школа; ГБОУ Школа №179; ГБОУ Школа 1574)
                        </li>
                        <li>
                            Подготовка к ЕГЭ и ОГЭ
                        </li>
                        <li>
                            Подготовка к ДВИ
                        </li>
                        <li>
                            Подготовка к олимпиадам: Всероссийской Олимпиаде Школьников, Московской Олимпиаде Школьников и другим, в том числе перечневым
                        </li>
                    </ul>
                </div>
            </div>
            <div className="md:px-36">
            <Slide>
                <div className="flex items-center justify-center bg-cover h-auto" style={{ height: 'auto' }}>
                    <div style={{ 
                    backgroundImage: `url(${images[0]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904' 
                    }}>
                    <span className="w-full"></span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 
                    backgroundImage: `url(${images[1]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904'
                    }}>
                    <span></span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 
                    backgroundImage: `url(${images[2]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904'
                    }}>
                    <span></span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 
                    backgroundImage: `url(${images[3]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904'
                    }}>
                    <span></span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 
                    backgroundImage: `url(${images[4]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904'
                    }}>
                    <span></span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 
                    backgroundImage: `url(${images[5]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904'
                    }}>
                    <span></span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 
                    backgroundImage: `url(${images[6]})`, 
                    backgroundSize: 'contain', 
                    backgroundPosition: 'center', 
                    height: '100%', 
                    width: '100%',
                    aspectRatio: '1280 / 904'
                    }}>
                    <span></span>
                    </div>
                </div>
                </Slide>
            </div>
        </div>
    )
}

export default About