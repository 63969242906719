import React from "react";
import { Document, Text, Page, StyleSheet, Image, View, Font } from "@react-pdf/renderer";
import {Table, TR, TH, TD} from '@ag-media/react-pdf-table';
import attentionMark from "./attention.png";
import goodMark from "./good.png";
import badMark from "./bad.png";

const PDF = ({date, user, testNumber, tasks, score, answer1, answer2, answer3, answer4, answer5, answer6, answer7, answer8, answer9, answer10, answer11, answer12, answer13, answer14, answer15, answer16, answer17, answer18, answer19, answer20, answer21, answer22, answer23, answer24, answer25, answer26, answer27, answer28}) => {

    Font.register({
        family: "IBM Plex Mono Medium",
        src:
          "https://fonts.cdnfonts.com/s/27347/IBMPlexMono-Medium.woff"
      });

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            fontFamily : "IBM Plex Mono Medium",
            fontSize: 14
        },
        subscript: {
            fontSize: 10, // уменьшенный размер шрифта
            verticalAlign: 'sub', // выравнивание по нижнему регистру
        }
    });

    function sortAnswerArrays(x, y){
        var pre = ['string' , 'number' , 'bool']
        if(typeof x!== typeof y )return pre.indexOf(typeof y) - pre.indexOf(typeof x);
    
        if(x === y)return 0;
        else return (x > y)?1:-1;
    }
    
    var currentUserName = user.localName ? user.localName : user.name;

    const preparedDate = date.toDate().getDate()+"."+(date.toDate().getMonth()+1)+"."+date.toDate().getFullYear();
    const currentTestNumberStr = testNumber.toString().slice(0, -1);
    const currentTestNumber = Number(currentTestNumberStr);

    var currentTask1 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 1);
    var currentTask2 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 2);
    var currentTask3 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 3);
    var currentTask4 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 4);
    var currentTask5 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 5);
    var currentTask6 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 6);
    var currentTask7 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 7);
    var currentTask8 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 8);
    var currentTask9 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 9);
    var currentTask10 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 10);
    var currentTask11 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 11);
    var currentTask12 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 12);
    var currentTask13 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 13);
    var currentTask14 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 14);
    var currentTask15 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 15);
    var currentTask16 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 16);
    var currentTask17 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 17);
    var currentTask18 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 18);
    var currentTask19 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 19);
    var currentTask20 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 20);
    var currentTask21 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 21);
    var currentTask22 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 22);
    var currentTask23 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 23);
    var currentTask24 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 24);
    var currentTask25 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 25);
    var currentTask26 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 26);
    var currentTask27 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 27);
    var currentTask28 = tasks.find((task) => task.testNumber === currentTestNumber && task.questionNumber === 28);

    var isRightTask1 = false;
    if(currentTask1 !== undefined){
        if(answer1.sort(sortAnswerArrays).toString() === currentTask1.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask1 = true;
        };
    }

    var isRightTask2 = false;
    if(currentTask2 !== undefined){
        if(answer2 === currentTask2.rightAnswer.join(", ")){
            isRightTask2 = true;
        };
    }

    var isRightTask3 = false;
    if(currentTask3 !== undefined){
        if(answer3.sort(sortAnswerArrays).toString() === currentTask3.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask3 = true;
        };
    }

    var isRightTask4 = false;
    if(currentTask4 !== undefined){
        if(answer4.sort(sortAnswerArrays).toString() === currentTask4.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask4 = true;
        };
    }

    var isRightTask5 = false;
    if(currentTask5 !== undefined){
        if(answer5 === currentTask5.rightAnswer.join(", ")){
            isRightTask5 = true;
        };
    }

    var isRightTask6 = false;
    if(currentTask6 !== undefined){
        if(answer6 === currentTask6.rightAnswer.join(", ")){
            isRightTask6 = true;
        };
    }

    var isRightTask7 = false;
    if(currentTask7 !== undefined){
        if(answer7 === currentTask7.rightAnswer.join(", ")){
            isRightTask7 = true;
        };
    }

    var isRightTask8 = false;
    if(currentTask8 !== undefined){
        if(answer8 === currentTask8.rightAnswer.join(", ")){
            isRightTask8 = true;
        };
    }

    var isRightTask9 = false;
    if(currentTask9 !== undefined){
        if(answer9 === currentTask9.rightAnswer.join(", ")){
            isRightTask9 = true;
        };
    }

    var isRightTask10 = false;
    if(currentTask10 !== undefined){
        if(answer10 === currentTask10.rightAnswer.join(", ")){
            isRightTask10 = true;
        };
    }

    var isRightTask11 = false;
    if(currentTask11 !== undefined){
        if(answer11.sort(sortAnswerArrays).toString() === currentTask11.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask11 = true;
        };
    }

    var isRightTask12 = false;
    if(currentTask12 !== undefined){
        if(answer12.sort(sortAnswerArrays).toString() === currentTask12.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask12 = true;
        };
    }

    var isRightTask13 = false;
    if(currentTask13 !== undefined){
        if(answer13.sort(sortAnswerArrays).toString() === currentTask13.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask13 = true;
        };
    }

    var isRightTask14 = false;
    if(currentTask14 !== undefined){
        if(answer14 === currentTask14.rightAnswer.join(", ")){
            isRightTask14 = true;
        };
    }

    var isRightTask15 = false;
    if(currentTask15 !== undefined){
        if(answer15 === currentTask15.rightAnswer.join(", ")){
            isRightTask15 = true;
        };
    }

    var isRightTask16 = false;
    if(currentTask16 !== undefined){
        if(answer16 === currentTask16.rightAnswer.join(", ")){
            isRightTask16 = true;
        };
    }

    // 12 задание
    var currentTask12FirstText = "";
    var currentTask12FirstImage = "";
    var currentTask12SecondText = "";
    var currentTask12SecondImage = "";
    var currentTask12ThirdText = "";
    var currentTask12ThirdImage = "";
    var currentTask12FourthText = "";
    var currentTask12FourthImage = "";
    var currentTask12FifthText = "";
    var currentTask12FifthImage = "";
    if(currentTask12 !== undefined){
        if(currentTask12.answer1 === ""){
            currentTask12FirstImage = currentTask12.imageFirstRef
        } else {
            currentTask12FirstText = currentTask12.answer1
        };
        if(currentTask12.answer2 === ""){
            currentTask12SecondImage = currentTask12.imageSecondRef
        } else {
            currentTask12SecondText = currentTask12.answer2
        };
        if(currentTask12.answer3 === ""){
            currentTask12ThirdImage = currentTask12.imageThirdRef
        } else {
            currentTask12ThirdText = currentTask12.answer3
        };
        if(currentTask12.answer4 === ""){
            currentTask12FourthImage = currentTask12.imageFourthRef
        } else {
            currentTask12FourthText = currentTask12.answer4
        };
        if(currentTask12.answer5 === ""){
            currentTask12FifthImage = currentTask12.imageFifthRef
        } else {
            currentTask12FifthText = currentTask12.answer5
        };
    };

    // 13 задание
    var currentTask13FirstText = "";
    var currentTask13FirstImage = "";
    var currentTask13SecondText = "";
    var currentTask13SecondImage = "";
    var currentTask13ThirdText = "";
    var currentTask13ThirdImage = "";
    var currentTask13FourthText = "";
    var currentTask13FourthImage = "";
    var currentTask13FifthText = "";
    var currentTask13FifthImage = "";
    if(currentTask13 !== undefined){
        if(currentTask13.answer1 === ""){
            currentTask13FirstImage = currentTask13.imageFirstRef
        } else {
            currentTask13FirstText = currentTask13.answer1
        };
        if(currentTask13.answer2 === ""){
            currentTask13SecondImage = currentTask13.imageSecondRef
        } else {
            currentTask13SecondText = currentTask13.answer2
        };
        if(currentTask13.answer3 === ""){
            currentTask13ThirdImage = currentTask13.imageThirdRef
        } else {
            currentTask13ThirdText = currentTask13.answer3
        };
        if(currentTask13.answer4 === ""){
            currentTask13FourthImage = currentTask13.imageFourthRef
        } else {
            currentTask13FourthText = currentTask13.answer4
        };
        if(currentTask13.answer5 === ""){
            currentTask13FifthImage = currentTask13.imageFifthRef
        } else {
            currentTask13FifthText = currentTask13.answer5
        };
    };

    // 14 задание
    var currentTask14SubsFirstText = "";
    var currentTask14SubsFirstImage = "";
    var currentTask14SubsSecondText = "";
    var currentTask14SubsSecondImage = "";
    var currentTask14SubsThirdText = "";
    var currentTask14SubsThirdImage = "";
    var currentTask14SubsFourthText = "";
    var currentTask14SubsFourthImage = "";
    if(currentTask14 !== undefined){
        if(currentTask14.substance1 === ""){
            currentTask14SubsFirstImage = currentTask14.imageFirstRefSubstance
        } else {
            currentTask14SubsFirstText = currentTask14.substance1
        };
        if(currentTask14.substance2 === ""){
            currentTask14SubsSecondImage = currentTask14.imageSecondRefSubstance
        } else {
            currentTask14SubsSecondText = currentTask14.substance2
        };
        if(currentTask14.substance3 === ""){
            currentTask14SubsThirdImage = currentTask14.imageThirdRefSubstance
        } else {
            currentTask14SubsThirdText = currentTask14.substance3
        };
        if(currentTask14.substance4 === ""){
            currentTask14SubsFourthImage = currentTask14.imageFourthRefSubstance
        } else {
            currentTask14SubsFourthText = currentTask14.substance4
        };
    };
    var currentTask14ReagFirstText = "";
    var currentTask14ReagFirstImage = "";
    var currentTask14ReagSecondText = "";
    var currentTask14ReagSecondImage = "";
    var currentTask14ReagThirdText = "";
    var currentTask14ReagThirdImage = "";
    var currentTask14ReagFourthText = "";
    var currentTask14ReagFourthImage = "";
    var currentTask14ReagFifthText = "";
    var currentTask14ReagFifthImage = "";
    var currentTask14ReagSixText = "";
    var currentTask14ReagSixImage = "";
    if(currentTask14 !== undefined){
        if(currentTask14.reagent1 === ""){
            currentTask14ReagFirstImage = currentTask14.imageFirstRefReagent
        } else {
            currentTask14ReagFirstText = currentTask14.reagent1
        };
        if(currentTask14.reagent2 === ""){
            currentTask14ReagSecondImage = currentTask14.imageSecondRefReagent
        } else {
            currentTask14ReagSecondText = currentTask14.reagent2
        };
        if(currentTask14.reagent3 === ""){
            currentTask14ReagThirdImage = currentTask14.imageThirdRefReagent
        } else {
            currentTask14ReagThirdText = currentTask14.reagent3
        };
        if(currentTask14.reagent4 === ""){
            currentTask14ReagFourthImage = currentTask14.imageFourthRefReagent
        } else {
            currentTask14ReagFourthText = currentTask14.reagent4
        };
        if(currentTask14.reagent5 === ""){
            currentTask14ReagFifthImage = currentTask14.imageFifthRefReagent
        } else {
            currentTask14ReagFifthText = currentTask14.reagent5
        };
        if(currentTask14.reagent6 === ""){
            currentTask14ReagSixImage = currentTask14.imageSixRefReagent
        } else {
            currentTask14ReagSixText = currentTask14.reagent6
        };
    };

    // 15 задание
    var currentTask15SubsFirstText = "";
    var currentTask15SubsFirstImage = "";
    var currentTask15SubsSecondText = "";
    var currentTask15SubsSecondImage = "";
    var currentTask15SubsThirdText = "";
    var currentTask15SubsThirdImage = "";
    var currentTask15SubsFourthText = "";
    var currentTask15SubsFourthImage = "";
    if(currentTask15 !== undefined){
        if(currentTask15.substance1 === ""){
            currentTask15SubsFirstImage = currentTask15.imageFirstRefSubstance
        } else {
            currentTask15SubsFirstText = currentTask15.substance1
        };
        if(currentTask15.substance2 === ""){
            currentTask15SubsSecondImage = currentTask15.imageSecondRefSubstance
        } else {
            currentTask15SubsSecondText = currentTask15.substance2
        };
        if(currentTask15.substance3 === ""){
            currentTask15SubsThirdImage = currentTask15.imageThirdRefSubstance
        } else {
            currentTask15SubsThirdText = currentTask15.substance3
        };
        if(currentTask15.substance4 === ""){
            currentTask15SubsFourthImage = currentTask15.imageFourthRefSubstance
        } else {
            currentTask15SubsFourthText = currentTask15.substance4
        };
    };
    var currentTask15ReagFirstText = "";
    var currentTask15ReagFirstImage = "";
    var currentTask15ReagSecondText = "";
    var currentTask15ReagSecondImage = "";
    var currentTask15ReagThirdText = "";
    var currentTask15ReagThirdImage = "";
    var currentTask15ReagFourthText = "";
    var currentTask15ReagFourthImage = "";
    var currentTask15ReagFifthText = "";
    var currentTask15ReagFifthImage = "";
    var currentTask15ReagSixText = "";
    var currentTask15ReagSixImage = "";
    if(currentTask15 !== undefined){
        if(currentTask15.reagent1 === ""){
            currentTask15ReagFirstImage = currentTask15.imageFirstRefReagent
        } else {
            currentTask15ReagFirstText = currentTask15.reagent1
        };
        if(currentTask15.reagent2 === ""){
            currentTask15ReagSecondImage = currentTask15.imageSecondRefReagent
        } else {
            currentTask15ReagSecondText = currentTask15.reagent2
        };
        if(currentTask15.reagent3 === ""){
            currentTask15ReagThirdImage = currentTask15.imageThirdRefReagent
        } else {
            currentTask15ReagThirdText = currentTask15.reagent3
        };
        if(currentTask15.reagent4 === ""){
            currentTask15ReagFourthImage = currentTask15.imageFourthRefReagent
        } else {
            currentTask15ReagFourthText = currentTask15.reagent4
        };
        if(currentTask15.reagent5 === ""){
            currentTask15ReagFifthImage = currentTask15.imageFifthRefReagent
        } else {
            currentTask15ReagFifthText = currentTask15.reagent5
        };
        if(currentTask15.reagent6 === ""){
            currentTask15ReagSixImage = currentTask15.imageSixRefReagent
        } else {
            currentTask15ReagSixText = currentTask15.reagent6
        };
    };

    // 16 задание
    var currentTask16Image = false;
    if(currentTask16 !== undefined){
        if(currentTask16.imageRef === ""){
            currentTask16Image = false;
        } else {
            currentTask16Image = true;
        };
    }

    var isRightTask17 = false;
    if(currentTask17 !== undefined){
        if(answer17.sort(sortAnswerArrays).toString() === currentTask17.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask17 = true;
        };
    }

    var isRightTask18 = false;
    if(currentTask18 !== undefined){
        if(answer18.sort(sortAnswerArrays).toString() === currentTask18.rightAnswer.sort(sortAnswerArrays).toString()){
            isRightTask18 = true;
        };
    }

    var isRightTask19 = false;
    if(currentTask19 !== undefined){
        if(answer19 === currentTask19.rightAnswer.join(", ")){
            isRightTask19 = true;
        };
    }

    var isRightTask20 = false;
    if(currentTask20 !== undefined){
        if(answer20 === currentTask20.rightAnswer.join(", ")){
            isRightTask20 = true;
        };
    }

    var isRightTask21 = false;
    if(currentTask21 !== undefined){
        if(answer21 === currentTask21.rightAnswer.join(", ")){
            isRightTask21 = true;
        };
    }

    var isRightTask22 = false;
    if(currentTask22 !== undefined){
        if(answer22 === currentTask22.rightAnswer.join(", ")){
            isRightTask22 = true;
        };
    }

    var currentTask23Image = false;
    if(currentTask23 !== undefined){
        if(currentTask23.imageRef === ""){
            currentTask23Image = false;
        } else {
            currentTask23Image = true;
        };
    }

    var isRightTask23 = false;
    if(currentTask23 !== undefined){
        if(answer23 === currentTask23.rightAnswer.join(", ")){
            isRightTask23 = true;
        };
    }

    var isRightTask24 = false;
    if(currentTask24 !== undefined){
        if(answer24 === currentTask24.rightAnswer.join(", ")){
            isRightTask24 = true;
        };
    }

    var isRightTask25 = false;
    if(currentTask25 !== undefined){
        if(answer25 === currentTask25.rightAnswer.join(", ")){
            isRightTask25 = true;
        };
    }

    var isRightTask26 = false;
    if(currentTask26 !== undefined){
        if(answer26 === currentTask26.rightAnswer){
            isRightTask26 = true;
        };
    }

    var isRightTask27 = false;
    if(currentTask27 !== undefined){
        if(answer27 === currentTask27.rightAnswer){
            isRightTask27 = true;
        };
    }

    var isRightTask28 = false;
    if(currentTask28 !== undefined){
        if(answer28 === currentTask28.rightAnswer){
            isRightTask28 = true;
        };
    }

    function containsNumber(str) {
        var newArrayForString = [];
        if(str !== undefined){
            for (let i = 0; i < str.length; i++) {
                if(/\d/.test(str[i]) === true) {
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else if(str[i] === '0') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push(str[i]);
                    }
                } else if(str[i] === '@') {
                    newArrayForString.push("");
                } else if(str[i] === '+'){
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push(str[i]);
                    };
                } else if(str[i] === '-'){
                    if(str[i-1] === '@') {
                        newArrayForString.push(str[i]);
                    } else {
                        newArrayForString.push(str[i]);
                    };
                } else {
                    newArrayForString.push(str[i]);
                };
            };
            return newArrayForString.join('');
        } else {
            return str;
        };
    };

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={{marginBottom: 10}}>
                    <Text>
                        Студент: {currentUserName}
                    </Text>
                    <Text>
                        Дата: {preparedDate}
                    </Text>
                    <Text>
                        Вариант: {currentTestNumber}
                    </Text>
                    <Text>
                        Кол-во набранных баллов: {score} / 36
                    </Text>
                </View>
                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 1 {isRightTask1 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask1 ? currentTask1.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask1 ? currentTask1.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask1 ? currentTask1.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask1 ? currentTask1.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask1 ? currentTask1.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask1 ? currentTask1.answer5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer1.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask1 ? currentTask1.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 2 {isRightTask2 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask2 ? currentTask2.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask2 ? currentTask2.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask2 ? currentTask2.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask2 ? currentTask2.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask2 ? currentTask2.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask2 ? currentTask2.answer5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer2}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask2 ? currentTask2.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 3 {isRightTask3 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask3 ? currentTask3.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask3 ? currentTask3.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask3 ? currentTask3.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask3 ? currentTask3.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask3 ? currentTask3.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask3 ? currentTask3.answer5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer3.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask3 ? currentTask3.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 4 {isRightTask4 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask4 ? currentTask4.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask4 ? currentTask4.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask4 ? currentTask4.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask4 ? currentTask4.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask4 ? currentTask4.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask4 ? currentTask4.answer5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer4.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask4 ? currentTask4.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 5 {isRightTask5 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask5 ? currentTask5.question : "no data"}
                    </Text>
                    <Table>
                        <TH>
                            <TD>1) {currentTask5 ? containsNumber(currentTask5.answer1) : "no data"}</TD>
                            <TD>2) {currentTask5 ? containsNumber(currentTask5.answer2) : "no data"}</TD>
                            <TD>3) {currentTask5 ? containsNumber(currentTask5.answer3) : "no data"}</TD>
                        </TH>
                        <TR>
                            <TD>4) {currentTask5 ? containsNumber(currentTask5.answer4) : "no data"}</TD>
                            <TD>5) {currentTask5 ? containsNumber(currentTask5.answer5) : "no data"}</TD>
                            <TD>6) {currentTask5 ? containsNumber(currentTask5.answer6) : "no data"}</TD>
                        </TR>
                        <TR>
                            <TD>7) {currentTask5 ? containsNumber(currentTask5.answer7) : "no data"}</TD>
                            <TD>8) {currentTask5 ? containsNumber(currentTask5.answer8) : "no data"}</TD>
                            <TD>9) {currentTask5 ? containsNumber(currentTask5.answer9) : "no data"}</TD>
                        </TR>
                    </Table>
                    <Text>
                        Ответ студента: {answer5}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask5 ? currentTask5.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 6 {isRightTask6 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask6 ? currentTask6.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask6 ? containsNumber(currentTask6.answer1) : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask6 ? containsNumber(currentTask6.answer2) : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask6 ? containsNumber(currentTask6.answer3) : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask6 ? containsNumber(currentTask6.answer4) : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask6 ? containsNumber(currentTask6.answer5) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer6}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask6 ? currentTask6.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 7 {isRightTask7 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask7 ? currentTask7.question : "no data"}
                    </Text>
                    <Text>
                        Вещества:
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask7 ? containsNumber(currentTask7.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask7 ? containsNumber(currentTask7.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask7 ? containsNumber(currentTask7.substance3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Г) {currentTask7 ? containsNumber(currentTask7.substance4) : "no data"}
                    </Text>
                    <Text>
                        Реагенты:
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask7 ? containsNumber(currentTask7.reagent1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask7 ? containsNumber(currentTask7.reagent2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask7 ? containsNumber(currentTask7.reagent3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask7 ? containsNumber(currentTask7.reagent4) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        5) {currentTask7 ? containsNumber(currentTask7.reagent5) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer7}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask7 ? currentTask7.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 8 {isRightTask8 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask8 ? currentTask8.question : "no data"}
                    </Text>
                    <Text>
                        Вещества:
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask8 ? containsNumber(currentTask8.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask8 ? containsNumber(currentTask8.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask8 ? containsNumber(currentTask8.substance3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Г) {currentTask8 ? containsNumber(currentTask8.substance4) : "no data"}
                    </Text>
                    <Text>
                        Реагенты:
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask8 ? containsNumber(currentTask8.reagent1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask8 ? containsNumber(currentTask8.reagent2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask8 ? containsNumber(currentTask8.reagent3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask8 ? containsNumber(currentTask8.reagent4) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        5) {currentTask8 ? containsNumber(currentTask8.reagent5) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        6) {currentTask8 ? containsNumber(currentTask8.reagent6) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer8}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask8 ? currentTask8.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>
                
                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 9 {isRightTask9 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask9 ? currentTask9.question : "no data"}
                    </Text>
                    <Image style={{maxWidth: 200, maxHeight: 50}} src={currentTask9 ? currentTask9.imageRef : null} />
                    <Text>
                        1) {currentTask9 ? containsNumber(currentTask9.answer1) : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask9 ? containsNumber(currentTask9.answer2) : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask9 ? containsNumber(currentTask9.answer3) : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask9 ? containsNumber(currentTask9.answer4) : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask9 ? containsNumber(currentTask9.answer5) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer9}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask9 ? currentTask9.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 10 {isRightTask10 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask10 ? currentTask10.question : "no data"}
                    </Text>
                    <Text>
                        Вещества:
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask10 ? containsNumber(currentTask10.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask10 ? containsNumber(currentTask10.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask10 ? containsNumber(currentTask10.substance3) : "no data"}
                    </Text>
                    <Text>
                        Реагенты:
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask10 ? currentTask10.reagent1 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask10 ? currentTask10.reagent2 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask10 ? currentTask10.reagent3 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask10 ? currentTask10.reagent4 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer10}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask10 ? currentTask10.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 11 {isRightTask11 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask11 ? currentTask11.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask11 ? currentTask11.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask11 ? currentTask11.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask11 ? currentTask11.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask11 ? currentTask11.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask11 ? currentTask11.answer5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer11.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask11 ? currentTask11.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 12 {isRightTask12 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask12 ? currentTask12.question : "no data"}
                    </Text>
                    <Image style={{maxWidth: 200, maxHeight: 50}} src={currentTask12 ? currentTask12.questionImageRef : null} />
                    <Text>
                        1) {currentTask12FirstText === "" ? <Image src={currentTask12FirstImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask12FirstText)}
                    </Text>
                    <Text>
                        2) {currentTask12SecondText === "" ? <Image src={currentTask12SecondImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask12SecondText)}
                    </Text>
                    <Text>
                        3) {currentTask12ThirdText === "" ? <Image src={currentTask12ThirdImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask12ThirdText)}
                    </Text>
                    <Text>
                        4) {currentTask12FourthText === "" ? <Image src={currentTask12FourthImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask12FourthText)}
                    </Text>
                    <Text>
                        5) {currentTask12FifthText === "" ? <Image src={currentTask12FifthImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask12FifthText)}
                    </Text>
                    <Text>
                        Ответ студента: {answer12.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask12 ? currentTask12.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 13 {isRightTask13 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask13 ? currentTask13.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask13FirstText === "" ? <Image src={currentTask13FirstImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask13FirstText)}
                    </Text>
                    <Text>
                        2) {currentTask13SecondText === "" ? <Image src={currentTask13SecondImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask13SecondText)}
                    </Text>
                    <Text>
                        3) {currentTask13ThirdText === "" ? <Image src={currentTask13ThirdImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask13ThirdText)}
                    </Text>
                    <Text>
                        4) {currentTask13FourthText === "" ? <Image src={currentTask13FourthImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask13FourthText)}
                    </Text>
                    <Text>
                        5) {currentTask13FifthText === "" ? <Image src={currentTask13FifthImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask13FifthText)}
                    </Text>
                    <Text>
                        Ответ студента: {answer13.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask13 ? currentTask13.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 14 {isRightTask14 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask14 ? currentTask14.question : "no data"}
                    </Text>
                    <Text>
                        {currentTask14 ? currentTask14.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask14SubsFirstText === "" ? <Image src={currentTask14SubsFirstImage} style={{width: "200px", height: "50px"}} /> : currentTask14SubsFirstText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask14SubsSecondText === "" ? <Image src={currentTask14SubsSecondImage} style={{width: "200px", height: "50px"}} /> : currentTask14SubsSecondText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask14SubsThirdText === "" ? <Image src={currentTask14SubsThirdImage} style={{width: "200px", height: "50px"}} /> : currentTask14SubsThirdText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Г) {currentTask14SubsFourthText === "" ? <Image src={currentTask14SubsFourthImage} style={{width: "200px", height: "50px"}} /> : currentTask14SubsFourthText}
                    </Text>
                    <Text>
                        {currentTask14 ? currentTask14.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask14ReagFirstText === "" ? <Image src={currentTask14ReagFirstImage} style={{width: "200px", height: "50px"}} /> : currentTask14ReagFirstText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask14ReagSecondText === "" ? <Image src={currentTask14ReagSecondImage} style={{width: "200px", height: "50px"}} /> : currentTask14ReagSecondText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask14ReagThirdText === "" ? <Image src={currentTask14ReagThirdImage} style={{width: "200px", height: "50px"}} /> : currentTask14ReagThirdText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask14ReagFourthText === "" ? <Image src={currentTask14ReagFourthImage} style={{width: "200px", height: "50px"}} /> : currentTask14ReagFourthText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        5) {currentTask14ReagFifthText === "" ? <Image src={currentTask14ReagFifthImage} style={{width: "200px", height: "50px"}} /> : currentTask14ReagFifthText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        6) {currentTask14ReagSixText === "" ? <Image src={currentTask14ReagSixImage} style={{width: "200px", height: "50px"}} /> : currentTask14ReagSixText}
                    </Text>
                    <Text>
                        Ответ студента: {answer14.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask14 ? currentTask14.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 15 {isRightTask15 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask15 ? currentTask15.question : "no data"}
                    </Text>
                    <Text>
                        {currentTask15 ? currentTask15.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask15SubsFirstText === "" ? <Image src={currentTask15SubsFirstImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask15SubsFirstText)}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask15SubsSecondText === "" ? <Image src={currentTask15SubsSecondImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask15SubsSecondText)}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask15SubsThirdText === "" ? <Image src={currentTask15SubsThirdImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask15SubsThirdText)}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Г) {currentTask15SubsFourthText === "" ? <Image src={currentTask15SubsFourthImage} style={{width: "200px", height: "50px"}} /> : containsNumber(currentTask15SubsFourthText)}
                    </Text>
                    <Text>
                        {currentTask15 ? currentTask15.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask15ReagFirstText === "" ? <Image src={currentTask15ReagFirstImage} style={{width: "200px", height: "50px"}} /> : currentTask15ReagFirstText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask15ReagSecondText === "" ? <Image src={currentTask15ReagSecondImage} style={{width: "200px", height: "50px"}} /> : currentTask15ReagSecondText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask15ReagThirdText === "" ? <Image src={currentTask15ReagThirdImage} style={{width: "200px", height: "50px"}} /> : currentTask15ReagThirdText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask15ReagFourthText === "" ? <Image src={currentTask15ReagFourthImage} style={{width: "200px", height: "50px"}} /> : currentTask15ReagFourthText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        5) {currentTask15ReagFifthText === "" ? <Image src={currentTask15ReagFifthImage} style={{width: "200px", height: "50px"}} /> : currentTask15ReagFifthText}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        6) {currentTask15ReagSixImage === "" ? currentTask15ReagSixText : <Image src={currentTask15ReagSixImage} style={{width: "200px", height: "50px"}} />}
                    </Text>
                    <Text>
                        Ответ студента: {answer15.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask15 ? currentTask15.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 16 {isRightTask16 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask16 ? currentTask16.question : "no data"}
                    </Text>
                    {currentTask16Image ? <Image style={{maxWidth: 200, maxHeight: 50}} src={currentTask16 ? currentTask16.imageRef : null} /> : ""}
                    <Text>
                        1) {currentTask16 ? containsNumber(currentTask16.answer1) : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask16 ? containsNumber(currentTask16.answer2) : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask16 ? containsNumber(currentTask16.answer3) : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask16 ? containsNumber(currentTask16.answer4) : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask16 ? containsNumber(currentTask16.answer5) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer16}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask16 ? currentTask16.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 17 {isRightTask17 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask17 ? currentTask17.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask17 ? containsNumber(currentTask17.answer1) : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask17 ? containsNumber(currentTask17.answer2) : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask17 ? containsNumber(currentTask17.answer3) : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask17 ? containsNumber(currentTask17.answer4) : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask17 ? containsNumber(currentTask17.answer5) : "no data"}
                    </Text>
                    <Text>
                        6) {currentTask17 ? containsNumber(currentTask17.answer6) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer17.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask17 ? currentTask17.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 18 {isRightTask18 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask18 ? currentTask18.question : "no data"}
                    </Text>
                    <Text>
                        1) {currentTask18 ? currentTask18.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask18 ? currentTask18.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask18 ? currentTask18.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask18 ? currentTask18.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask18 ? currentTask18.answer5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer18.join(", ")}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask18 ? currentTask18.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 19 {isRightTask19 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask19 ? currentTask19.question : "no data"}
                    </Text>
                    <Text>
                        {currentTask19 ? currentTask19.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask19 ? containsNumber(currentTask19.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask19 ? containsNumber(currentTask19.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask19 ? containsNumber(currentTask19.substance3) : "no data"}
                    </Text>
                    <Text>
                        {currentTask19 ? currentTask19.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask19 ? currentTask19.reagent1 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask19 ? currentTask19.reagent2 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask19 ? currentTask19.reagent3 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask19 ? currentTask19.reagent4 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer19}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask19 ? currentTask19.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 20 {isRightTask20 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask20 ? currentTask20.question : "no data"}
                    </Text>
                    <Text>
                        {currentTask20 ? currentTask20.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask20 ? containsNumber(currentTask20.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask20 ? containsNumber(currentTask20.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask20 ? containsNumber(currentTask20.substance3) : "no data"}
                    </Text>
                    <Text>
                        {currentTask20 ? currentTask20.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask20 ? containsNumber(currentTask20.reagent1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask20 ? containsNumber(currentTask20.reagent2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask20 ? containsNumber(currentTask20.reagent3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        4) {currentTask20 ? containsNumber(currentTask20.reagent4) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        5) {currentTask20 ? currentTask20.reagent5 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer20}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask20 ? currentTask20.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 21 {isRightTask21 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask21 ? currentTask21.question : "no data"}
                    </Text>
                    <Image style={{maxWidth: 200, maxHeight: 50}} src={currentTask21 ? currentTask21.imageRef : null} />
                    <Text>
                        1) {currentTask21 ? currentTask21.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask21 ? currentTask21.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask21 ? currentTask21.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask21 ? currentTask21.answer4 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer21}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask21 ? currentTask21.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 22 {isRightTask22 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask22 ? containsNumber(currentTask22.question) : "no data"}
                    </Text>
                    <Text>
                        {currentTask22 ? currentTask22.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask22 ? containsNumber(currentTask22.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask22 ? containsNumber(currentTask22.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask22 ? containsNumber(currentTask22.substance3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Г) {currentTask22 ? containsNumber(currentTask22.substance4) : "no data"}
                    </Text>
                    <Text>
                        {currentTask22 ? currentTask22.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask22 ? containsNumber(currentTask22.reagent1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask22 ? containsNumber(currentTask22.reagent2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask22 ? containsNumber(currentTask22.reagent3) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer22}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask22 ? currentTask22.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 23 {isRightTask23 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask23 ? currentTask23.question : "no data"}
                    </Text>
                    {currentTask23Image ? <Image style={{maxWidth: 200, maxHeight: 50}} src={currentTask23 ? currentTask23.imageRef : null} /> : ""}
                    <Text>
                        1) {currentTask23 ? currentTask23.answer1 : "no data"}
                    </Text>
                    <Text>
                        2) {currentTask23 ? currentTask23.answer2 : "no data"}
                    </Text>
                    <Text>
                        3) {currentTask23 ? currentTask23.answer3 : "no data"}
                    </Text>
                    <Text>
                        4) {currentTask23 ? currentTask23.answer4 : "no data"}
                    </Text>
                    <Text>
                        5) {currentTask23 ? currentTask23.answer5 : "no data"}
                    </Text>
                    <Text>
                        6) {currentTask23 ? currentTask23.answer6 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer23}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask23 ? currentTask23.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 24 {isRightTask24 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask24 ? currentTask24.question : "no data"}
                    </Text>
                    <Text>
                        {currentTask24 ? currentTask24.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask24 ? containsNumber(currentTask24.substance1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask24 ? containsNumber(currentTask24.substance2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask24 ? containsNumber(currentTask24.substance3) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Г) {currentTask24 ? containsNumber(currentTask24.substance4) : "no data"}
                    </Text>
                    <Text>
                        {currentTask24 ? currentTask24.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask24 ? containsNumber(currentTask24.reagent1) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask24 ? containsNumber(currentTask24.reagent2) : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask24 ? containsNumber(currentTask24.reagent3) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer24}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask24 ? currentTask24.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 25 {isRightTask25 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask25 ? currentTask25.question : "no data"}
                    </Text>
                    <Text>
                        {currentTask25 ? currentTask25.nameOfSubstance : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        А) {currentTask25 ? currentTask25.substance1 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        Б) {currentTask25 ? currentTask25.substance2 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        В) {currentTask25 ? currentTask25.substance3 : "no data"}
                    </Text>
                    <Text>
                        {currentTask25 ? currentTask25.nameOfReagent : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        1) {currentTask25 ? currentTask25.reagent1 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        2) {currentTask25 ? currentTask25.reagent2 : "no data"}
                    </Text>
                    <Text style={{marginLeft: 10}}>
                        3) {currentTask25 ? currentTask25.reagent3 : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer25}
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask25 ? currentTask25.rightAnswer.join(", ") : "no data"}
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 26 {isRightTask26 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask26 ? currentTask26.question : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer26} ({currentTask26 ? currentTask26.measuring : "no data"})
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask26 ? currentTask26.rightAnswer : "no data"} ({currentTask26 ? currentTask26.measuring : "no data"})
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 27 {isRightTask27 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask27 ? containsNumber(currentTask27.question) : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer27} ({currentTask27 ? currentTask27.measuring : "no data"})
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask27 ? currentTask27.rightAnswer : "no data"} ({currentTask27 ? currentTask27.measuring : "no data"})
                    </Text>
                </View>

                <View style={{marginLeft: 10, marginBottom: 10}}>
                    <Text style={{marginLeft: -10}}>
                        Задание 28 {isRightTask28 ? <Image src={goodMark} style={{width: "30px", height: "30px"}} /> : <Image src={badMark} style={{width: "30px", height: "30px"}} />}
                    </Text>
                    <Text>
                        {currentTask28 ? currentTask28.question : "no data"}
                    </Text>
                    <Text>
                        Ответ студента: {answer28} ({currentTask28 ? currentTask28.measuring : "no data"})
                    </Text>
                    <Text>
                        Правильный ответ: {currentTask28 ? currentTask28.rightAnswer : "no data"} ({currentTask28 ? currentTask28.measuring : "no data"})
                    </Text>
                </View>
            </Page>
        </Document>
    )
}

export default PDF;