// import React, { useEffect, useState, useRef } from "react";
// import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
// import { database } from "../firebase";
// import venezuelaFlag from "./venezuela.png";
// import noImage from './noImage.jpg';
// import refresh from './refresh.png';

// function SolarSpanish() {

//     const uploadData = <UploadData />;
//     const venezuelan = <Venezuelan />;

//     return (
//         <div>
//             {venezuelan}
//         </div>
//     )
// }

// export default SolarSpanish;

// function Venezuelan() {
//     const [input, setInput] = useState("");
//     const [toggle, setToggle] = useState(false);
//     const [isLoading, setIsLoading] = useState();
//     const [data, setData] = useState([]);
//     const [currentNumber, setCurrentNumber] = useState("");
//     const [currentWord, setCurrentWord] = useState();

//     const pathToTheBase = collection(database, "wordsTest");
//     const pathToTheProgress = collection(database, "solarUpdates");

//     function toggleReload() {
//         if(toggle === false) {
//             setToggle(true)
//         } else {
//             setToggle(false)
//         };
//     };

//     function showInput() {
//         var inputDiv = document.getElementById("inputAndButton");
//         inputDiv.classList.remove("hidden");
//     };

//     function nextWord() {
//         var inputDiv = document.getElementById("inputAndButton");
//         inputDiv.classList.add("hidden");
//         setInput("");
//     };

//     const refreshData = () => {
//         toggleReload();
//         console.log(currentNumber)
//         const currentWordObject = data.filter(word => word.id === "wordID_"+currentNumber);
//         setCurrentWord(currentWordObject)
//         // console.log(currentWord)
//     }

//     useEffect(()=> {
//         var tempString = "";
        
//         const getData = async() => {
//             setIsLoading(true)
//             const dbData = await getDocs(pathToTheBase)
//             let receivedData = dbData.docs.map(doc=>({...doc.data(), id:doc.id}))
//             setData(receivedData);
//             const dbProgress = await getDocs(pathToTheProgress)
//             let receivedDataProgress = dbProgress.docs.map(doc=>({...doc.data(), id:doc.id}))
//             receivedDataProgress.map(values => (
//                 tempString = values.lastCheckedWord
//             ));
//             setCurrentNumber(tempString);
//             setIsLoading(false)
//         }
//         getData()
//         // console.log("data received")
//     },[toggle]);

//     // if(data !== undefined){
//     //     const currentWordObject = data.filter(word => word.id === "wordID_"+currentNumber);
//     //     setCurrentWord(currentWordObject)
//     // }

//     return (
//         <div className="flex flex-col justify-center items-center">
//             <div className="flex flex-row items-center">
//                 <img className="h-16 px-4" src={venezuelaFlag} />
//                 <h2 className="text-2xl">Venezuela</h2>
//                 <button onClick={refreshData}><img className="h-12 px-4" src={refresh} /></button>
//             </div>
//             {currentWord ?
//             currentWord.map(value => {
//                 <p>{value.id}</p>
//             }): ""}
//             <img className="h-56 w-56 border-2 rounded-2xl" src={noImage} />
//             <p className="text-2xl">article + Spanish word</p>
//             <p>Translation eng</p>
//             <p>Translation rus</p>
//             <div className="space-x-4">
//                 <button onClick={showInput} className="hover:shadow-lg px-3 py-2 mt-0 text-2xl text-center bg-violet-500 text-white font-black rounded-full">Another↓</button>
//                 <button onClick={nextWord} className="hover:shadow-lg px-3 py-2 mt-0 text-2xl text-center bg-amber-500 text-white font-black rounded-full">The same→</button>
//             </div>
//             <div className="w-full px-5 mt-4 flex flex-col items-end hidden" id="inputAndButton">
//                 <input value={input} onChange={(e) => setInput(e.target.value)} className="text-center appearance-none w-full block bg-white text-gray-700 border border-gray-500 rounded-xl py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
//                 <button className="mt-4 hover:shadow-lg px-3 py-2 mt-0 text-2xl text-center bg-green-500 text-white font-black rounded-lg">Done</button>
//             </div>
//         </div>
//     )
// }

import React, { useEffect, useState, useMemo, useRef } from "react";
import { collection, getDocs, setDoc, doc, updateDoc } from 'firebase/firestore';
import { database } from "../firebase";
import venezuelaFlag from "./venezuela.png";
import noImage from './noImage.jpg';
import refresh from './refresh.png';

function SolarSpanish() {
    return (
        <div>
            <Venezuelan />
        </div>
    );
}

export default SolarSpanish;

function Venezuelan() {
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentNumber, setCurrentNumber] = useState("");
    
    const pathToTheBase = collection(database, "wordsTest");
    const pathToTheProgress = collection(database, "solarUpdates");

    // Загружаем данные один раз при монтировании компонента
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                
                // Запрос данных из wordsTest
                const dbData = await getDocs(pathToTheBase);
                const receivedData = dbData.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setData(receivedData);

                // Запрос текущего номера слова из solarUpdates
                const dbProgress = await getDocs(pathToTheProgress);
                const lastCheckedWord = dbProgress.docs[0]?.data().lastCheckedWord || "";
                setCurrentNumber(lastCheckedWord);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Мемоизация текущего слова на основе currentNumber
    const currentWord = useMemo(() => {
        return data.find(word => word.id === `wordID_${currentNumber}`);
    }, [data, currentNumber]);

    // Функция для обновления currentNumber и получения следующего слова
    const getNextWord = () => {
        hadleCheckWord();
        const nextNumber = parseInt(currentNumber) + 1;
        setCurrentNumber(nextNumber.toString());
    };

    // Функция для отображения поля ввода
    const showInput = () => {
        const inputDiv = document.getElementById("inputAndButton");
        inputDiv.classList.toggle("hidden");
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    let article = '';
    if (currentWord !== undefined) {
        if(currentWord.type === 'noun'){
            if(currentWord.gender === 'm'){
                article = 'el';
            }else if(currentWord.gender === 'f'){
                article = 'la';
            }else if(currentWord.gender === 'm(pl)'){
                article = 'los';
            }else if(currentWord.gender === 'f(pl)'){
                article = 'las';
            }else if(currentWord.gender === 'f(el)'){
                article = 'el';
            }else{
                console.log('error in article')
            };
        };
    }


    // const pathToProcessedWords = collection(database, "solarDone");

    const hadleCheckWord = async (e) => {
        // e.preventDefault();
        await setDoc(doc(database, "solarDone", "wordID_"+currentNumber), {
            word: currentWord.wordOrPhrase,
            isTheSame: input === "" ? true : false,
            anotherWord: input,
            id: parseInt(currentNumber),
            country: "Venezuela"
        });
        await updateDoc(doc(pathToTheProgress, "venezuelaCount"), {
            lastCheckedWord: parseInt(currentNumber)+1
        });
        setInput("");
    };

    return (

        <div className="flex flex-col justify-center items-center">
            <div className="flex flex-row items-center">
                <img className="h-16 px-4" src={venezuelaFlag} />
                <h2 className="text-2xl">Venezuela</h2>
                <button><img className="h-12 px-4" src={refresh} /></button>
            </div>
            {currentWord ? (
                <div className="text-center">
                    <img className="h-56 w-56 border-2 rounded-2xl" src={'./images/'+currentWord.id+'.jpeg'} alt={currentWord.wordOrPhrase} />
                    <p className="text-2xl">{article + " "+currentWord.wordOrPhrase}</p>
                    <p>{currentWord.engTranslation.join(", ")}</p>
                    <p>{currentWord.rusTranslation.join(", ")}</p>
                </div>
            ) : (
                <p>No word found.</p>
            )}
            <div className="space-x-4">
                <button onClick={showInput} className="hover:shadow-lg px-3 py-2 mt-0 text-2xl text-center bg-violet-500 text-white font-black rounded-full">Another↓</button>
                <button onClick={getNextWord} className="hover:shadow-lg px-3 py-2 mt-0 text-2xl text-center bg-amber-500 text-white font-black rounded-full">{input === "" ? "The same→" : "Done"}</button>
            </div>
            <div className="w-full px-5 mt-4 flex flex-col items-end hidden" id="inputAndButton">
                <input value={input} onChange={(e) => setInput(e.target.value)} className="text-center appearance-none w-full block bg-white text-gray-700 border border-gray-500 rounded-xl py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder=""></input>
                {/* <button className="mt-4 hover:shadow-lg px-3 py-2 mt-0 text-2xl text-center bg-green-500 text-white font-black rounded-lg">Done</button> */}
            </div>
        </div>

        // <div className="flex flex-col justify-center items-center">
        //     <div className="space-x-4 mt-4">
        //         <button
        //             onClick={showInput}
        //             className="hover:shadow-lg px-3 py-2 text-2xl bg-violet-500 text-white font-black rounded-full"
        //         >
        //             Show Input
        //         </button>
        //         <button
        //             onClick={getNextWord}
        //             className="hover:shadow-lg px-3 py-2 text-2xl bg-amber-500 text-white font-black rounded-full"
        //         >
        //             Next Word →
        //         </button>
        //     </div>

        //     <div className="w-full px-5 mt-4 flex flex-col items-end hidden" id="inputAndButton">
        //         <input
        //             value={input}
        //             onChange={(e) => setInput(e.target.value)}
        //             className="text-center appearance-none w-full bg-white text-gray-700 border border-gray-500 rounded-xl py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        //             type="text"
        //             placeholder="Your input here"
        //         />
        //         <button className="mt-4 hover:shadow-lg px-3 py-2 text-2xl bg-green-500 text-white font-black rounded-lg">
        //             Submit
        //         </button>
        //     </div>
        // </div>
    );
}


// function UploadData() {
//     const [jsonContent, setJsonContent] = useState([]);
//     const fileRef = useRef();
//     const [isLoadingGetDataFromServer, setIsLoadingGetDataFromServer] = useState();
//     const [isLoadingSendDataToServer, setIsLoadingSendDataToServer] = useState();
//     const [references, setReferences] = useState([]);
//     const [refLastNumber, setRefLastNumber] = useState(0);
//     const [isLoadingGetRefsFromServer, setIsLoadingGetRefsFromServer] = useState();
//     const [isLoadingSendNewRefToServer, setIsLoadingSendNewRefToServer] = useState();
//     const [isLoadingSendUpdateRefToServer, setIsLoadingSendUpdateRefToServer] = useState();
//     const [selectedRefForUpdate, setSelectedRefForUpdate] = useState();

//     const onChangeFile = async (e) => {
//         if (e.target.files && e.target.files[0]) {
//         //   const updatedJSON = e.target.files[0];
//         //   console.log(updatedJSON);
//             const fileReader = new FileReader();
//             fileReader.readAsText(e.target.files[0], "UTF-8");
//             fileReader.onload = e => {
//             // console.log("e.target.result", e.target);
//             const target = e.target;
//             const result = target?.result;
//             var myObject = JSON.parse(result);
//             var array = [];
//             for (var key in myObject) {
//                 var item = myObject[key];
//                 array.push(item);
//             }
//             setJsonContent(array);
//             // console.log(array.length)
//         }
//     }};

//     async function addDoc(obj) {
//         setIsLoadingSendDataToServer(true);
//         await setDoc(doc(database, "wordsTest", obj.id), {
//             id: obj.id,
//             wordOrPhrase: obj.wordOrPhrase,
//             type: obj.type,
//             style: obj.style,
//             isPronominalVerb: obj.isPronominalVerb,
//             engTranslation: obj.engTranslation,
//             rusTranslation: obj.rusTranslation,
//             topics: obj.topics,
//             hasAudioArgentino: obj.hasAudioArgentino,
//             gender: obj.gender,
//             countries: obj.countries,
//             example: obj.example,
//             exampleTenses: obj.exampleTenses,
//             engExample: obj.engExample,
//             rusExample: obj.rusExample,
//             engExplanation: obj.engExplanation,
//             rusExplanation: obj.rusExplanation,
//             engAnalog: obj.engAnalog,
//             rusAnalog: obj.rusAnalog,
//             nounGenders: obj.nounGenders,
//             adjGenders: obj.adjGenders,
//             isRegularVerb: obj.isRegularVerb,
//             presenteDeIndicativo: obj.presenteDeIndicativo,
//             preteritoPerfectoSimple: obj.preteritoPerfectoSimple,
//             preteritoPerfecto: obj.preteritoPerfecto,
//             preteritoImperfecto: obj.preteritoImperfecto,
//             futuroDeIndicativo: obj.futuroDeIndicativo,
//             imperativoAfirmativo: obj.imperativoAfirmativo,
//             imperativoNegativo: obj.imperativoNegativo,
//             presenteDeSubjuntivo: obj.presenteDeSubjuntivo
//         });
//         setIsLoadingSendDataToServer(false)
//     };

//     function sendWordsToServer() {
//         // console.log(array.length + 1);
//         var wordsCount = jsonContent.length + 1;
//         var newObject = {};
//         jsonContent.forEach((element) => {
//             var objectInside = {};
//             if(element.phrase) {
//                 objectInside.id = `wordID_${wordsCount}`;
//                 wordsCount += 1;
//             } else {
//                 objectInside.id = element.id;
//             }
//             if(element.word) {
//                 objectInside.wordOrPhrase = element.word;
//             } else if(element.phrase) {
//                 objectInside.wordOrPhrase = element.phrase;
//             } else {
//                 objectInside.wordOrPhrase = "ERROR!";
//             };
//             if(element.type) {
//                 objectInside.type = element.type;
//             }else{
//                 objectInside.type = "";
//             };
//             if(element.style) {
//                 objectInside.style = element.style;
//             }else{
//                 objectInside.style = "";
//             };
//             if(element.reflexive === "yes") {
//                 objectInside.isPronominalVerb = true
//             } else if (element.reflexive === "no") {
//                 objectInside.isPronominalVerb = false
//             } else {
//                 objectInside.isPronominalVerb = false
//             };
//             if(element.verbtype === "irregular") {
//                 objectInside.isRegularVerb = false
//             } else if (element.verbtype === "regular") {
//                 objectInside.isRegularVerb = true
//             } else {
//                 objectInside.isRegularVerb = false
//             };
//             objectInside.engTranslation = Object.values(element.engtranslation);
//             objectInside.rusTranslation = Object.values(element.rustranslation);
//             objectInside.topics = Object.values(element.topics);
//             if(element.hasaudioargentino === "yes") {
//                 objectInside.hasAudioArgentino = true;
//             } else if(element.hasaudioargentino === "no") {
//                 objectInside.hasAudioArgentino = false;
//             } else {
//                 objectInside.hasAudioArgentino = false;
//             };
//             if(element.gender) {
//                 objectInside.gender = element.gender;
//             }else{
//                 objectInside.gender = "";
//             };
//             let arrayOfFlags = Object.values(element.country);
//             let newArrayOfFlags = arrayOfFlags.map(item => {
//                 let newItem = item.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
//                 return newItem;
//             });
//             objectInside.countries = newArrayOfFlags;
//             if(element.example) {
//                 objectInside.example = element.example;
//             }else{
//                 objectInside.example = "";
//             };
//             if(element.example_tenses) {
//                 objectInside.exampleTenses = Object.values(element.example_tenses);
//             } else {
//                 objectInside.exampleTenses = [""];
//             };
//             if(element.example_eng) {
//                 objectInside.engExample = element.example_eng;
//             } else {
//                 objectInside.engExample = "";
//             };
//             if(element.example_rus) {
//                 objectInside.rusExample = element.example_rus;
//             } else {
//                 objectInside.rusExample = "";
//             };
//             if(element.explanation_eng) {
//                 objectInside.engExplanation = element.explanation_eng;
//             } else {
//                 objectInside.engExplanation = "";
//             };
//             if(element.explanation_rus) {
//                 objectInside.rusExplanation = element.explanation_rus;
//             } else {
//                 objectInside.rusExplanation = "";
//             };
//             if(element.eng_analog) {
//                 objectInside.engAnalog = element.eng_analog;
//             } else {
//                 objectInside.engAnalog = "";
//             };
//             if(element.rus_analog) {
//                 objectInside.rusAnalog = element.rus_analog;
//             } else {
//                 objectInside.rusAnalog = "";
//             };
//             if(element.noungenders) {
//                 objectInside.nounGenders = element.noungenders;
//             }else{
//                 objectInside.nounGenders = "";
//             };
//             if(element.adjgenders) {
//                 objectInside.adjGenders = element.adjgenders;
//             }else{
//                 objectInside.adjGenders = "";
//             };
//             if(element.presenteDeIndicativo) {
//                 objectInside.presenteDeIndicativo = element.presenteDeIndicativo;
//             } else {
//                 objectInside.presenteDeIndicativo = "";
                
//             };
//             if(element.preterito) {
//                 objectInside.preteritoPerfectoSimple = element.preterito;
//             } else {
//                 objectInside.preteritoPerfectoSimple = "";
//             };
//             if(element.preteritoPerfecto) {
//                 objectInside.preteritoPerfecto = element.preteritoPerfecto;
//             } else {
//                 objectInside.preteritoPerfecto = "";
//             };
//             if(element.imperfectodeIndicativo) {
//                 objectInside.preteritoImperfecto = element.imperfectodeIndicativo;
//             } else {
//                 objectInside.preteritoImperfecto = "";
//             };
//             if(element.futurodeIndicativo) {
//                 objectInside.futuroDeIndicativo = element.futurodeIndicativo;
//             } else {
//                 objectInside.futuroDeIndicativo = "";
//             };
//             if(element.imperativoAfirmativo) {
//                 objectInside.imperativoAfirmativo = element.imperativoAfirmativo;
//             } else {
//                 objectInside.imperativoAfirmativo = "";
//             };
//             if(element.imperativoNegativo) {
//                 objectInside.imperativoNegativo = element.imperativoNegativo;
//             } else {
//                 objectInside.imperativoNegativo = "";
//             };
//             if(element.presenteDeSubjuntivo) {
//                 objectInside.presenteDeSubjuntivo = element.presenteDeSubjuntivo;
//             } else {
//                 objectInside.presenteDeSubjuntivo = "";
//             };

//             addDoc(objectInside);
//         });
//         // console.log(newObject);
//     };


//     return(
//         <div>
//             <h3 className='text-center text-2xl py-1'>Local Data</h3>
//             <input
//                 type="file"
//                 id="input_json"
//                 ref={fileRef}
//                 onChange={onChangeFile}
//                 className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 my-4 mx-2 border border-gray-400 rounded shadow-md"
//             /><br></br>
//             <div className='text-left px-3 pb-1'>
//                 Count: {jsonContent.length !== 0 ? <span>{jsonContent.length}</span> : "upload file please"}
//             </div>
//             <button onClick={sendWordsToServer} className="bg-white hover:bg-blue-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border border-gray-400 hover:border-transparent rounded">
//                 {isLoadingSendDataToServer ? "Loading ..." : "Send data to server"}
//             </button>
//         </div>
//     )
// }