import React from "react";
import { useNavigate } from 'react-router-dom';
import { UserAuth } from "./context/AuthContext";
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { database } from "./firebase";

function MainPage() {
    const navigate = useNavigate();
    const { user } = UserAuth();

    async function addUserData() {
        await setDoc(doc(database, "users", user.auth.currentUser.uid), {
            name: user.auth.currentUser.displayName ? user.auth.currentUser.displayName : "Имя отсутствует",
            localName: "Имя отсутствует",
            role: "student",
            id: user.auth.currentUser.uid
          });
    };

    function getUserData() {
        const getData = async() => {
            const docRef = doc(database, "users", user.auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                console.log("uid: "+user.auth.currentUser.uid);
                if(docSnap.data().role === "admin") {
                    navigate('/accountadmin')
                } else {
                    navigate('/accountstudent')
                };
            } else {
              console.log("No such document!");
              await addUserData();
              navigate('/accountstudent')
            }
        }
        getData();
    };
    console.log("uid: "+user.auth.currentUser.uid);
    useEffect(()=> {
        getUserData();
    }, [])

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-white">
            <img src="loadingAnimation.gif" />
            <h1 className="text-3xl">Загрузка...</h1>
        </div>
    )
}

export default MainPage;