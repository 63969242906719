import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom'
import MainPage from './MainPage';
import NavBar from './NavBar';
import { AuthContextProvider } from './context/AuthContext';
import Signin from './Signin';
import AccountStudent from './AccountStudent';
import Protected from './Protected';
import AccountAdmin from './AccountAdmin';
import LoadingPage from './LoadingPage';
import SolarSpanish from './SolarSpanish/SolarSpanish';
import About from './About';

function App() {
  return (
    <div>
      <AuthContextProvider>
        <NavBar />

        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/loading' element={<LoadingPage />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/about' element={<About />} />
          <Route path='/accountstudent' element={<Protected><AccountStudent /></Protected>} />
          <Route path='/accountadmin' element={<Protected><AccountAdmin /></Protected>} />
          <Route path='/solarspanish' element={<Protected><SolarSpanish /></Protected>} />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
